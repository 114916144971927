<div class="manage-wrapper">
    <div class="manage-header">
        <div class="page-titles">
            <h2 class="title">Dispatch Roles</h2>
            <p class="subtitle">Track, manage and forecast your customers and orders.</p>
        </div>
        <div class="actions">
            <button type="button" class="btn" appDebounceClick (debounceClick)="prepareDeleteConnections()">Share history</button>
            <button type="button" class="btn" appDebounceClick (debounceClick)="prepareDuplicate()"[disabled]="isShareDisabled()" [ngClass]="{ 'disabled-btn': isShareDisabled()}">Share</button>
            <button type="button" class="btn" appDebounceClick (debounceClick)="prepareReassign()" [disabled]="isReassignDisabled()" [ngClass]="{ 'disabled-btn': isReassignDisabled()}">Reassign</button>
            <button type="button" class="btn recycle-btn" appDebounceClick (debounceClick)="recycleBin()">
                <svg-icon src="assets/svg/bin.svg" alt="bin"></svg-icon>
                Deactivated
            </button>
        </div>
    </div>
    <div class="manage-content">
        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-blue"></div>
                    <h3 class="column-title">Supervisors</h3>
                    <div class="count-block">{{ supervisors.length }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" placeholder="Search" type="text" [(ngModel)]="searchSupervisorKey" (input)="filterSupervisorsByKey()" />
                    </div> 
                    <div class="clear" (click)="clearSupervisors()">
                        <svg-icon class="clear-icon" src="assets/svg/metla.svg" alt="clear"></svg-icon>
                    </div>
                    <div class="add">
                        <svg-icon class="add-icon" (click)="addUserClick(['Supervisor'])" src="assets/svg/plus.svg" alt="add"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="hr-line blue-line"></div>
            @defer (when supervisors.length > 0) {
                @if(supervisors.length === 0) {
                    <div class="empty-dispatchers">
                        <svg-icon src="assets/svg/empty-supervisors.svg"></svg-icon>
                    </div>
                } @else {
                    <div class="list">
                        <div class="list-header">
                            <input type="checkbox" id="checkbox-supervisors" class="custom-checkbox minus" [checked]="supervisorsChecked" (click)="$event.stopPropagation(); checkSupervisors()">
                            <label for="checkbox-supervisors"></label>
                            <p class="list-header-text name">Name</p>
                        </div>
                        <div class="row" *ngFor="let supervisor of supervisors; let i = index" [ngClass]="{ 'selected': supervisor.selected }" (click)="selectSupervisor(supervisor)">
                            <input type="checkbox" [id]="'checkbox-supervisor' + i" class="custom-checkbox" [checked]="supervisor.checked" (click)="$event.stopPropagation(); checkSupervisor(supervisor)" />
                            <label [for]="'checkbox-supervisor' + i" (click)="$event.stopPropagation()"></label>
                            <p class="name">{{ supervisor.fullName }}</p>
                            <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                <ng-template #editTooltipContent>
                                    <ul class="manage-edit-tooltip">
                                        <li (click)="editUserClick(supervisor)"
                                            [ngClass]="{ 'disabled-btn': !supervisor.accessRights.isEditable}"
                                            matRipple
                                            [matRippleDisabled]="!supervisor.accessRights.isEditable"
                                        >
                                            <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                            <p>Edit</p>
                                        </li>
                                        <li (click)="deleteUserClick(supervisor, ['Supervisor'])" 
                                            [ngClass]="{ 'disabled-btn': !supervisor.accessRights.isDeletable}"
                                            matRipple
                                            [matRippleDisabled]="!supervisor.accessRights.isDeletable"
                                        >
                                            <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                            <p>Delete</p>
                                        </li>
                                    </ul>
                                    </ng-template>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-yellow"></div>
                    <h3 class="column-title">Dispatchers</h3>
                    <div class="count-block">{{ dispatchersCount }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" placeholder="Search" type="text" [(ngModel)]="searchDispatcherKey" (input)="filterDispatchersByKey()" />
                    </div>
                    <div class="clear" (click)="clearDispatchers()">
                        <svg-icon class="clear-icon" src="assets/svg/metla.svg" alt="clear"></svg-icon>
                    </div>
                    <div class="add">
                        <svg-icon class="add-icon" (click)="addUserClick(['Dispatcher'])"  src="assets/svg/plus.svg" alt="add"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="hr-line yellow-line"></div>
            @defer (when dispatchers.length > 0) {
                <div class="list" [ngClass]="{ 'users-bloks': dispatchersDisplay === 'blocks' || dispatchers.length === 0 }">
                    @if(dispatchersDisplay === 'list') {
                        @if(dispatchers.length === 0) {
                            <div class="empty-dispatchers">
                                <svg-icon src="assets/svg/empty-dispatchers.svg"></svg-icon>
                            </div>
                        } @else {
                            <div class="list-header">
                                <input type="checkbox" id="checkbox-dispatchers" class="custom-checkbox minus" [checked]="dispatchersChecked" (click)="$event.stopPropagation(); checkDispatchers()">
                                <label for="checkbox-dispatchers"></label>
                                <p class="list-header-text name">Name</p>
                                
                            </div>
                            <div class="row" *ngFor="let dispatcher of dispatchers; let i = index" [ngClass]="{ 'selected': dispatcher.selected }" (click)="selectDispatcher(dispatcher)">
                                <input type="checkbox" [id]="'checkbox-dispatcher' + i" class="custom-checkbox" [checked]="dispatcher.checked" (click)="$event.stopPropagation(); checkDispatcher(dispatcher)" />
                                <label [for]="'checkbox-dispatcher' + i" (click)="$event.stopPropagation()"></label>
                                <p class="name">{{ dispatcher.fullName }}</p>
                                <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                    <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                    <ng-template #editTooltipContent>
                                        <ul class="manage-edit-tooltip">
                                            <li (click)="editUserClick(dispatcher)" 
                                                [ngClass]="{ 'disabled-btn': !dispatcher.accessRights.isEditable}"
                                                matRipple
                                                [matRippleDisabled]="!dispatcher.accessRights.isEditable"
                                                >
                                                <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                <p>Edit</p>
                                            </li>
                                            <li (click)="deleteUserClick(dispatcher, ['Dispatcher'])" 
                                                [ngClass]="{ 'disabled-btn': !dispatcher.accessRights.isDeletable}"
                                                matRipple
                                                [matRippleDisabled]="!dispatcher.accessRights.isDeletable"
                                                >
                                                <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                <p>Delete</p>
                                            </li>
                                        </ul>
                                      </ng-template>
                                </div>
                            </div>
                        }
                    } @else if (dispatchersDisplay === 'blocks') {
                        @if(dispatcherBlocks.length === 0) {
                            <div class="empty-dispatchers">
                                <svg-icon src="assets/svg/empty-dispatchers.svg"></svg-icon>
                            </div>
                        } @else {
                            <div class="block" *ngFor="let block of dispatcherBlocks; let blockIdx = index">
                                <div class="carrier-dispatcher">
                                    <div class="users-check-block">
                                        <img src="assets/svg/users-check.svg" alt="">
                                    </div>
                                    <div class="tied-users">
                                        <div class="tied-user">
                                            <h4 class="supervisor">Supervisor: </h4>
                                            <h4 class="supervisor-name">{{ block.supervisorName }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-header">
                                    <input type="checkbox" id="checkbox-dispatchers" class="custom-checkbox minus" [checked]="dispatchersChecked" (click)="$event.stopPropagation(); checkDispatchers()">
                                    <label for="checkbox-dispatchers"></label>
                                    <p class="list-header-text name">Name</p>
                                    
                                </div>
                                <div class="row" *ngFor="let dispatcher of block.array; let dispIdx = index" [ngClass]="{ 'selected': dispatcher.selected }" (click)="selectDispatcher(dispatcher)">
                                    <input type="checkbox" [id]="'checkbox-dispatcher-block' + blockIdx + dispIdx" class="custom-checkbox" [checked]="dispatcher.checked" (click)="$event.stopPropagation(); checkDispatcher(dispatcher)" />
                                    <label [for]="'checkbox-dispatcher-block' + blockIdx + dispIdx" (click)="$event.stopPropagation()"></label>
                                    <p class="name">{{ dispatcher.fullName }}</p>
                                    <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                        <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                        <ng-template #editTooltipContent>
                                            <ul class="manage-edit-tooltip">
                                                <li (click)="editUserClick(dispatcher)" 
                                                    [ngClass]="{ 'disabled-btn': !dispatcher.accessRights.isEditable}"
                                                    matRipple
                                                    [matRippleDisabled]="!dispatcher.accessRights.isEditable"
                                                >
                                                    <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                    <p>Edit</p>
                                                </li>
                                                <li (click)="deleteUserClick(dispatcher, ['Dispatcher'])" 
                                                    [ngClass]="{ 'disabled-btn': !dispatcher.accessRights.isDeletable}"
                                                    matRipple
                                                    [matRippleDisabled]="!dispatcher.accessRights.isDeletable"
                                                >
                                                    <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                    <p>Delete</p>
                                                </li>
                                            </ul>
                                          </ng-template>
                                    </div>
                                </div>
                            </div>
                        }
                    }
                </div>
            }
        </div>

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-green"></div>
                    <h3 class="column-title">Drivers</h3>
                    <div class="count-block">{{ driversCount }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" placeholder="Search" type="text" [(ngModel)]="searchDriverKey" (input)="filterDriversByKey()" />
                    </div>
                    <div class="clear" (click)="clearDrivers()">
                        <svg-icon class="clear-icon" src="assets/svg/metla.svg" alt="clear"></svg-icon>
                    </div>
                    <div class="add" (click)="addDriver()">
                        <svg-icon class="add-icon" src="assets/svg/plus.svg" alt="add"></svg-icon>
                    </div>
                </div>
            </div>

            <div class="hr-line green-line"></div>
            @defer (when drivers.length > 0) {
                <div class="list" [ngClass]="{ 'users-bloks': driversDisplay === 'blocks' || drivers.length === 0}">

                    @if (driversDisplay === 'blocks' || driversDisplay === 'both') {
                        @if(driverBlocks.length === 0) {
                            <div class="empty-drivers">
                                <svg-icon src="assets/svg/empty-drivers.svg"></svg-icon>
                            </div>
                        } @else {
                            <div class="block" *ngFor="let block of driverBlocks; let blockIdx = index">
                                <div class="carrier-dispatcher">
                                    <div class="users-check-block">
                                        <img src="assets/svg/users-check.svg" alt="">
                                    </div>
                                    <div class="tied-users">
                                        <div class="tied-user">
                                            <h4 class="supervisor">Supervisor: </h4>
                                            <h4 class="supervisor-name">{{ block.supervisorName }}</h4>
                                        </div>
                                        <div class="tied-user">
                                            @if(block.dispatcherName) {
                                                <h4 class="supervisor">Dispatcher: </h4>
                                                <h4 class="supervisor-name">{{ block.dispatcherName }}</h4>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="list-header">
                                    <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                                    <label for="checkbox-drivers"></label>
                                    <p class="list-header-text name">Name</p>
                                    
                                </div>
                                <div class="row" *ngFor="let driver of block.array; let driverIdx = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                                    <input type="checkbox" [id]="'checkbox-driver-block' + blockIdx + driverIdx" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                                    <label [for]="'checkbox-driver-block' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                    @if(driver.coDriverId && driver.coDriverFirstName && driver.coDriverLastName) {
                                        <p class="name">{{driver.fullName+','}} <span class="codriver-name">{{'+'+driver.coDriverFirstName+' '+driver.coDriverLastName}}</span></p>
                                    } @else {
                                        <p class="name">{{ driver.fullName }}</p>
                                    }
                                    <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                        <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                        <ng-template #editTooltipContent>
                                            <ul class="manage-edit-tooltip">
                                                <li (click)="editUserClick(driver, ['Driver'])" 
                                                    [ngClass]="{ 'disabled-btn': !driver.accessRights.isEditable}"
                                                    matRipple
                                                    [matRippleDisabled]="!driver.accessRights.isEditable"
                                                >
                                                    <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                    <p>Edit</p>
                                                </li>
                                                <li (click)="deleteUserClick(driver, ['Driver'])" 
                                                    [ngClass]="{ 'disabled-btn': !driver.accessRights.isDeletable}"
                                                    matRipple
                                                    [matRippleDisabled]="!driver.accessRights.isDeletable"
                                                >
                                                    <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                    <p>Delete</p>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        }
                    }

                    @if (driversDisplay === 'list' || driversDisplay === 'both') {
                        @if(drivers.length === 0) {
                            <div class="empty-dispatchers">
                                <svg-icon src="assets/svg/empty-drivers.svg"></svg-icon>
                            </div>
                        } @else {
                            <div class="list-header">
                                <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                                <label for="checkbox-drivers"></label>
                                <p class="list-header-text name">Name</p>
                                
                            </div>
                            <div class="row" *ngFor="let driver of drivers; let i = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                                <input type="checkbox" [id]="'checkbox-driver' + i" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                                <label [for]="'checkbox-driver' + i" (click)="$event.stopPropagation()"></label>
                                @if(driver.coDriverId && driver.coDriverFirstName && driver.coDriverLastName) {
                                    <p class="name">{{driver.fullName+','}} <span class="codriver-name">{{'+'+driver.coDriverFirstName+' '+driver.coDriverLastName}}</span></p>
                                } @else {
                                    <p class="name">{{ driver.fullName }}</p>
                                }
                                <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
                                    <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
                                    <ng-template #editTooltipContent>
                                        <ul class="manage-edit-tooltip">
                                            <li (click)="editUserClick(driver, ['Driver'])" 
                                                [ngClass]="{ 'disabled-btn': !driver.accessRights.isEditable}"
                                                matRipple
                                                [matRippleDisabled]="!driver.accessRights.isEditable"
                                            >
                                                <svg-icon class="edit-icon" src="assets/svg/manage-edit.svg" alt="edit"></svg-icon>
                                                <p>Edit</p>
                                            </li>
                                            <li (click)="deleteUserClick(driver, ['Driver'])" 
                                                [ngClass]="{ 'disabled-btn': !driver.accessRights.isDeletable}"
                                                matRipple
                                                [matRippleDisabled]="!driver.accessRights.isDeletable"
                                            >
                                                <svg-icon class="delete-icon" src="assets/svg/manage-delete.svg" alt="delete"></svg-icon>
                                                <p>Delete</p>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </div>
                            </div>
                        }
                    } 
                </div>
            }
        </div>
    </div>
</div>