import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { Connection } from '../../interfaces/connection';
import { firstValueFrom, Subject, take } from 'rxjs';
import { LoaderService } from '../../../../../../../services/loader/loader.service';
import { PopUpSubscriptBase } from '../../../../../../../services/popup-service/popup_subscribe_services';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionsService } from '../../services/connections/connections.service';

@Component({
  selector: 'app-delete-connections',
  templateUrl: './delete-connections.component.html',
  styleUrls: ['./delete-connections.component.scss', '../../../../../main.module.styles.scss'],
})
export class DeleteConnectionsComponent extends PopUpSubscriptBase implements OnInit, OnDestroy {

  constructor(
    private dialogService: DialogService,
    // private sharesService: SharesService
    private connService: ConnectionsService,
    private loaderService: LoaderService,
    private dialogRef: MatDialogRef<DeleteConnectionsComponent>
  ) {
    super(new Subject<any>());
  }

  authorsList: any[] = []
  fromList: any[] = []
  toList: any[] = []
  connectionIds: string[] = []
  connections: Connection[] = [];
  allConnections: Connection[] = []
  readonly panelOpenState = signal(false);

  selectedAuthor: Array<any> = new Array<any>();
  selectedFrom: Array<any> = new Array<any>();
  selectedTo: Array<any> = new Array<any>();
  isLoading: boolean = true;


  protected override showAPopUp(data: any): void {
    // console.log(data);
  }

  ngOnInit(): void {
    this.getConnections();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  };

  async getConnections() {
    try {
      this.loaderService.show();
      this.connections = await firstValueFrom(this.connService.getConnections());
      this.allConnections = structuredClone(this.connections);
      this.loadFilterDataSource();
      this.isLoading = false;
      this.loaderService.hide();
    } catch (error) {
      this.isLoading = false;
      this.loaderService.hide();
    }
  }

  loadFilterDataSource() {
    this.authorsList = this.allConnections.map(m=> ({fromName: m.fromName,fromId: m.fromId}) );
    this.fromList = this.allConnections.map(m=> ({sharedUserName: m.sharedUserName,sharedUserId: m.sharedUserId}) );
    this.toList = this.allConnections.map(m=> ({toName: m.toName,toId: m.toId}) );    
  }

  close(): void {
    this.dialogRef.close();
  }

  closeBtnClick(){
    this._result.next({type:"close"});
  }

  deleteConnection(){
    const deleted = this.connections.filter(c => c.checked);
    const ids = deleted.map(c => c.id);
    this._result.next({type:"ok", ids});
  }
  
  toggleAuthor = (author: any) => {
    author.selected = !author.selected;
    this.filterConnections();
  };

  toggleFrom = (from: any) => {
    from.selected = !from.selected;
    this.filterConnections();
  };

  toggleTo = (to: any) => {
    to.selected = !to.selected;
    this.filterConnections();
  };

  filterConnections() {
    let isFilterApplied = false;
    let filteredData = [...this.allConnections];
    
    // Filter by author
    if (this.selectedAuthor && this.selectedAuthor.length > 0) {
      isFilterApplied = true; // A filter is applied
      filteredData = filteredData.filter(f => 
        this.selectedAuthor.some((s: any) => f.fromId === s.fromId)
      );
    }

    // Filter by 'from'
    if (this.selectedFrom && this.selectedFrom.length > 0) {
      isFilterApplied = true; // A filter is applied
      filteredData = filteredData.filter(f => 
        this.selectedFrom.some((s: any) => f.sharedUserId === s.sharedUserId)
      );
    }

    // Filter by 'to' (corrected condition)
    if (this.selectedTo && this.selectedTo.length > 0) {
      isFilterApplied = true; // A filter is applied
      filteredData = filteredData.filter(f => 
        this.selectedTo.some((s: any) => f.toId === s.toId)
      );
    }

    // If no filters are applied, reset to the full list of connections
    if (!isFilterApplied) {
      filteredData = [...this.allConnections]; // Restore original data
    }

    // Update the connections with the filtered data
    this.connections = [...filteredData];
  }


  clearFilters() {
    this.selectedAuthor.forEach(element => {
      element.selected = false;
    });
    this.selectedFrom.forEach(driver => {
      driver.selected = null;
    });
    this.selectedTo.forEach(dispatcher => {
      dispatcher.selected = false;
    });

    this.selectedAuthor = [];
    this.selectedFrom = [];
    this.selectedTo = [];
  }

  clearValues(): void {
    // this.authors.setValue('')
    // this.from.setValue('')
    // this.to.setValue('')
  }

  isDeleteDisabled(): boolean {
    return this.connections.filter(f=> f.checked).length === 0;
  }

  submit(): void {
    // let deletedConnections: AdminShare[] = this.connections.filter(share => share.receivedDriverShares.some(item => this.connectionIds.includes(item.shareId)) || share.sentDriverShares.some(item => this.connectionIds.includes(item.shareId)))

    // deletedConnections = deletedConnections.map(connection => {
    //   connection.receivedDriverShares = connection.receivedDriverShares.filter(c => this.shareIds.includes(c.shareId))
    //   connection.sentDriverShares = connection.sentDriverShares.filter(c => this.shareIds.includes(c.shareId))
    //   return connection
    // })

    // this.sharesService.shares = deletedConnections

    // this.dialog.closeAllDialogs()
    // this.dialog.openDialog(DeteledSuccessfullyComponent)
  }

  addShare = (shareId: string): void => {
    // if (this.connectionIds.includes(shareId)) {
    //   const index = this.connectionIds.findIndex(el => el === shareId)
    //   this.connectionIds.splice(index, 1)
    // } else {
    //   this.connectionIds.push(shareId)
    // }
    // this.shares.forEach(share => {
    //   [...share.receivedDriverShares, ...share.sentDriverShares].forEach(el => {
    //     if (this.connectionIds.includes(el.shareId)) {
    //       el.selected = true
    //     } else {
    //       el.selected = false
    //     }
    //   })
    // })
  }
}