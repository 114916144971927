import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicate-drivers',
  templateUrl: './duplicate-drivers.component.html',
  styleUrls: ['./duplicate-drivers.component.scss', '../../../../../main.module.styles.scss']
})
export class DuplicateDriversComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DuplicateDriversComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  checkCarrier(carrier: any): void {
    carrier.checked = !carrier.checked
  }

  onSave() {
    const checkedCarriers = this.data.carriers.filter((c: any) => c.checked)

    this.dialogRef.close({
      carriers: checkedCarriers,
      drivers: this.data.drivers
    });
  }
}
