<div class="checkbox-wrapper-6">
    <input 
      class="tgl tgl-light" 
      [id]="idValue" 
      type="checkbox" 
      [(ngModel)]="checked" 
      (change)="emitValue(checked)" 
    />
    <label class="tgl-btn" [for]="idValue"></label>
  </div>
  