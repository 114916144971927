import { Component, Input } from '@angular/core';
import { Connection } from '../../interfaces/connection';
import { UserRolesCode } from '../../../../../../../enum/user-role.enum';

@Component({
  selector: 'app-connection-item',
  templateUrl: './connection-item.component.html',
  styleUrl: './connection-item.component.scss'
})
export class ConnectionItemComponent {

  @Input() connection!: Connection;

  check(): void {
    this.connection.checked = !this.connection.checked;
  }

  capitalizeFirstLetter(word: string) {
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  getIcon(role: string): string {
    const roleLowerCase:string = role.toLowerCase();
    let icon: string = '';
    
    switch (roleLowerCase) {
      case UserRolesCode.DISPATCHER.toLowerCase() : 
        icon = 'dispatcher-faded-icon.svg';
      break;
      case UserRolesCode.SUPERVISOR.toLowerCase() :
        icon = 'supervisor-faded-icon.svg';
      break;
      case UserRolesCode.DRIVER.toLowerCase() :
        icon = 'steering-faded-icon.svg';
      break;
    }
    return icon;
  }
}