import { Component, OnDestroy, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { PopUpSubscriptBase } from '../../../../../../../services/popup-service/popup_subscribe_services';
import { AccessRights } from '../../../../../../../interfaces/access-rights';

@Component({
  selector: 'app-select-duplicated-drivers-scope',
  templateUrl: './select-duplicated-drivers-scope.component.html',
  styleUrls: ['./select-duplicated-drivers-scope.component.scss', '../../../../../main.module.styles.scss'],
})
export class SelectDuplicatedDriversScopeComponent extends PopUpSubscriptBase implements OnInit, OnDestroy{

  constructor(
    private dialogRef: MatDialogRef<SelectDuplicatedDriversScopeComponent>
  ) {
    super(new Subject<any>());
  }

  selectedScope: AccessRights = {
    isDeletable: false,
    isEditable: false,
    isReassignable: false,
    isSharable: false,
  }

  scopeOptions = [
    { id: 'isEditable', label: 'Can edit', value: this.selectedScope.isEditable },
    { id: 'isDeletable', label: 'Can delete', value: this.selectedScope.isDeletable },
    { id: 'isSharable', label: 'Can duplicate', value: this.selectedScope.isSharable },
    { id: 'isReassignable', label: 'Can reassign', value: this.selectedScope.isReassignable },
  ];

  public data: any;

  protected override showAPopUp(data: any): void {
    // console.log(data);
    this.data = data;
  }

  ngOnInit(): void { }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  };

  onScopeChange(id: keyof typeof this.selectedScope, checked: boolean): void {
    this.selectedScope[id] = checked;
  }

  check(value: boolean) {
    value = !value;
  }

  okBtnClick() {    
    this._result.next({ type: "ok", scope: this.selectedScope });
  }


  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.data = undefined;
    this.dialogRef.close();
  }
}
