<div class="adduser-form-wrapper">
    <form [formGroup]="userForm">
        <div class="form-caption">Account</div>
        <div class="row-3" [ngClass]="{'hide-supervisor': !selectSuperVisor}">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name">
                <div class="input-err" *ngIf="userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched">
                    <div *ngIf="userForm.get('firstName').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        FirstName is required
                    </div>
                </div>
            </div>
    
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name">
                <div class="input-err" *ngIf="userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched">
                    <div *ngIf="userForm.get('lastName').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        LastName is required
                    </div>
                </div>
            </div>
            @if (selectSuperVisor) {
                <mat-form-field class="global-select-input">
                    @if(!userForm.get('supervisorId')?.value){
                        <mat-label>Supervisor</mat-label>
                    }
                    <mat-select formControlName="supervisorId" >
                    @for (supervisor of superVisorsSource; track $index) {
                        <mat-option [value]="supervisor.id">{{supervisor.fullName}}</mat-option>
                    }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="userForm.get('supervisorId')?.invalid && userForm.get('supervisorId')?.touched">
                        <mat-error *ngIf="userForm.get('supervisorId').errors?.required">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Supervisor is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            }
        </div>
        <div class="row-2">
            <div class="block-input-icon-with-err email-user-input">
                <svg-icon src="assets/svg/mail.svg"></svg-icon>
                <input formControlName="email" type="email" mode="Search" placeholder="Email" />
                <div class="input-err" *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched">
                    <div *ngIf="userForm.get('email').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Email is required
                    </div>
                    <div *ngIf="userForm.get('email').errors?.email">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Invalid email format
                    </div>
                </div>
            </div>
            <div class="block-input-icon-with-err">
                <ngx-intl-tel-input style="width: 100%;" 
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true" 
                  [enablePlaceholder]="true" 
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="true" 
                  [selectedCountryISO]="CountryISO.UnitedStates" 
                  [separateDialCode]="true"
                  [maxLength]="15" 
                  [phoneValidation]="true"
                  name="phone" 
                  formControlName="phone">
                </ngx-intl-tel-input>
              
                <div class="input-err" *ngIf="userForm.get('phone')?.invalid && userForm.get('phone')?.touched">
                  <div *ngIf="userForm.get('phone').errors?.required">
                    <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                    Phone number is required
                  </div>
                  <div *ngIf="userForm.get('phone').errors && userForm.get('phone').value">
                    <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                    Invalid phone number
                  </div>
                </div>
            </div>

        </div>
        <div class="form-caption">Security</div>
        <div class="row-2 pass_field">

            <div class="block-input-icon-with-err">
                <input [type]="fieldTextType" class="form-control" id="password" formControlName="password" placeholder="Password" autocomplete="new-password">
                <svg-icon src="assets/svg/show-password.svg" (click)="togglePassType()"></svg-icon>
                <div class="input-err" *ngIf="userForm.get('password')?.invalid && (userForm.get('password')?.dirty || userForm.get('password')?.touched)">
                    <div *ngIf="userForm.get('password').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Password is required
                    </div>
                    <div *ngIf="userForm.get('password').errors?.['pattern']">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Password must contain one number, one uppercase and a
                        lowercase letter and 8 characters
                    </div>
                </div>
            </div>
            <div class="block-input-icon-with-err">
                <input [type]="fieldTextType" class="form-control" id="password_confirm" formControlName="password_confirm" placeholder="Password confirm" autocomplete="new-password">
                <svg-icon src="assets/svg/show-password.svg" (click)="togglePassType()"></svg-icon>
                <div class="input-err" *ngIf="userForm.get('password_confirm')?.invalid && (userForm.get('password_confirm')?.dirty || userForm.get('password_confirm')?.touched)">
                    <div *ngIf="userForm.get('password_confirm').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Password is required
                    </div>
                    <div *ngIf="userForm.get('password')?.valid || userForm.get('password_confirm').errors?.['confirmedValidator']">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Passwords did not match
                    </div>
                </div>
            </div>
        </div>
    </form>
  </div>