import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reassign-equipment',
  templateUrl: './reassign-equipment.component.html',
  styleUrls: ['./reassign-equipment.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignEquipmentComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReassignEquipmentComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  checkCarrier(carrier: any): void {
    this.data.carriers?.forEach((c: any) => c.checked = false)
    this.data.drivers?.forEach((d: any) => d.checked = false)
    carrier.checked = true
  }

  checkDriver(driver: any): void {
    this.data.carriers?.forEach((c: any) => c.checked = false)
    this.data.drivers?.forEach((d: any) => d.checked = false)
    driver.checked = true
  }

  submit(): void {
    const checkedCarrier = this.data.carriers.find((s: any) => s.checked)
    const checkedDriver = this.data.drivers?.find((d: any) => d.checked)

    this.dialogService.closeDialog(this.dialogRef, {
      carrier: checkedCarrier,
      driver: checkedDriver,
      trailers: this.data.trailers,
      vehicles: this.data.vehicles
    });
  }
}
