<div class="popup-wrapper">

    <div class="header">
        <div class="img-block">
            <img src="assets/svg/credit-card-refresh.svg" alt="">
        </div>
        <h2 class="title">{{data.reassign ? 'Reassign' : 'Share'}}</h2>
        <button class="close" (click)="close()">
            <img src="assets/svg/x-close.svg" alt="close">
        </button>
    </div>

    <div class="popup-content">
        @switch (true) {
            <!-- share only driver -->
            @case ( data.hasOwnProperty('allCheckedDrivers') && !data.hasOwnProperty('allCheckedDispatchers') ) {
                @if (data.allDispatchers && data.allDispatchers.length) {
                    <div class="block">
                        <h3 class="block-title">Select Dispatchers</h3>
                        <div class="list">
                            @for (dispatcher of data.allDispatchers; track $index) {
                                <button class="row" (click)="checkDispatcher(dispatcher)" [ngClass]="{ 'selected-share': dispatcher.checked }">
                                    <svg-icon src="assets/svg/dispatcher-faded-icon.svg"></svg-icon>
                                    <p class="name">{{ dispatcher.fullName }}</p>
                                    @if (dispatcher.checked) {
                                        <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkDispatcher(dispatcher)">
                                    } @else {
                                        <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkDispatcher(dispatcher)">
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <div class="block">
                        <h3 class="block-title">Select Supervisors</h3>
                        <div class="list">
                            @for (supervisor of data.allSupervisors; track $index) {
                                <button class="row" (click)="checkSupervisor(supervisor)" [ngClass]="{ 'selected-share': supervisor.checked }">
                                    <svg-icon src="assets/svg/supervisor-faded-icon.svg"></svg-icon>
                                    <p class="name">{{ supervisor.fullName }}</p>
                                    @if (supervisor.checked) { 
                                        <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkSupervisor(supervisor)">
                                    } @else {
                                        <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkSupervisor(supervisor)">
                                    }
                                </button>
                            }
                        </div>
                    </div>
                }
            }
            <!-- share only dispatcher -->
            @case ( !data.hasOwnProperty('allCheckedDrivers') && data.hasOwnProperty('allCheckedDispatchers') ) {
                @if (data.allSupervisors && data.allSupervisors.length) {
                    <div class="block">
                        <h3 class="block-title">Select Supervisors</h3>
                        <div class="list">
                            @for (supervisor of data.allSupervisors; track $index) {
                                <button class="row" (click)="checkSupervisor(supervisor)" [ngClass]="{ 'selected-share': supervisor.checked }">
                                    <svg-icon src="assets/svg/supervisor-faded-icon.svg"></svg-icon>
                                    <p class="name">{{ supervisor.fullName }}</p>
                                    @if (supervisor.checked) { 
                                        <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkSupervisor(supervisor)">
                                    } @else {
                                        <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkSupervisor(supervisor)">
                                    }
                                </button>
                            }
                        </div>
                    </div>
                }
            }
            <!-- share driver & dispatcher same time -->
            <!-- @case (data.hasOwnProperty('allCheckedDrivers') && data.hasOwnProperty('allCheckedDispatchers') ) {
                @if ( (data.allSupervisors && data.allSupervisors.length) && (data.allDispatchers && data.allDispatchers.length) ) {
                    <div class="block">
                        <h3 class="block-title">Select Supervisors</h3>
                        <div class="list">
                            @for (supervisor of data.allSupervisors; track $index) {
                                <button class="row" (click)="checkSupervisor(supervisor)" [ngClass]="{ 'selected-share': supervisor.checked }">
                                    <svg-icon src="assets/svg/supervisor-faded-icon.svg"></svg-icon>
                                    <p class="name">{{ supervisor.fullName }}</p>
                                    @if (supervisor.checked) { 
                                        <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkSupervisor(supervisor)">
                                    } @else {
                                        <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkSupervisor(supervisor)">
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <div class="block">
                        <h3 class="block-title">Select Dispatchers</h3>
                        <div class="list">
                            @for (dispatcher of data.allDispatchers; track $index) {
                                <button class="row" (click)="checkDispatcher(dispatcher)" [ngClass]="{ 'selected-share': dispatcher.checked }">
                                    <svg-icon src="assets/svg/dispatcher-faded-icon.svg"></svg-icon>
                                    <p class="name">{{ dispatcher.fullName }}</p>
                                    @if (dispatcher.checked) {
                                        <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkDispatcher(dispatcher)">
                                    } @else {
                                        <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkDispatcher(dispatcher)">
                                    }
                                </button>
                            }
                        </div>
                    </div>
                }
            } -->
        }
    </div>
    <div class="buttons">
        <button class="btn cancel" (click)="closeBtnClick()">Cancel</button>
        <button class="btn save" (click)="okBtnClick()" [disabled]="isOkBtnDisabled()" [ngClass]="{ 'disabled-btn': isOkBtnDisabled()}">{{data.reassign ? 'Reassign' : 'Share'}}</button>
    </div>
</div>