<div class="adduser-popup-wrapper">
    <mat-tab-group #tabs [dynamicHeight]="true" mat-stretch-tabs="false" fitInkBarToContent="false" mat-align-tabs="start" [(selectedIndex)]="selectedTabIdx" >
        <mat-tab label="Add Dispatcher">
            <ng-template matTabContent>
                <app-create-user-form (formValid)="handleFormValidity($event)" [selectSuperVisor]="true"></app-create-user-form>
            </ng-template>
        </mat-tab>
        <mat-tab label="Add Supervisor">
            <ng-template matTabContent>
                <app-create-user-form (formValid)="handleFormValidity($event)" [selectSuperVisor]="false"></app-create-user-form>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button class="global-btn btn-cancel" (click)="closeBtnClick()">
                <span class="btn-text">Cancel</span>
            </button>
            <button appDebounceClick (debounceClick)="okBtnClick()" [disabled]="!formControl.isValid" [ngClass]="{ 'disabled-btn': !formControl.isValid }" class="global-btn btn-confirm">
                <span class="btn-text">Confirm</span>
            </button>
        </div>
    </div>
  </div>