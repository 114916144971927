import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Supervisor } from '../../interfaces/supervisor';
import { Dispatcher } from '../../interfaces/dispatcher';
import { Driver } from '../../interfaces/driver';
import { PopUpSubscriptBase } from '../../../../../../../services/popup-service/popup_subscribe_services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duplicate-to-users',
  templateUrl: './duplicate-to-users.component.html',
  styleUrls: ['./duplicate-to-users.component.scss', '../../../../../main.module.styles.scss']
})
export class DuplicateToUsersComponent extends PopUpSubscriptBase implements OnInit, OnDestroy{

  constructor(
    private dialogRef: MatDialogRef<DuplicateToUsersComponent>
  ) {
    super(new Subject<any>());
  }

  checkedSupervisors: Supervisor[] = []
  checkedDispatchers: Dispatcher[] = []
  checkedDrivers: Driver[] = []

  public data: any;

  protected override showAPopUp(data: any): void {
    // console.log(data);
    this.data = structuredClone(data);
  }

  ngOnInit(): void { }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.data = undefined;
  };

  checkSupervisor(supervisor: any): void {
    if(this.data.reassign) {
      if('allCheckedDrivers' in this.data){
        this.data.allDispatchers.forEach((dispatcher: any) => {
          dispatcher.checked = false;
        });
        this.data.allSupervisors.forEach((supervisor: any) => {
          supervisor.checked = false;
        });
      } else {
        this.data.allSupervisors.forEach((supervisor: any) => {
          supervisor.checked = false;
        });
      }
    }
    supervisor.checked = !supervisor.checked
  }

  checkDispatcher(dispatcher: any): void {
    if(this.data.reassign) {
      if('allCheckedDrivers' in this.data){
        this.data.allDispatchers.forEach((dispatcher: any) => {
          dispatcher.checked = false;
        });
        this.data.allSupervisors.forEach((supervisor: any) => {
          supervisor.checked = false;
        });
      } else {
        this.data.allDispatchers.forEach((dispatcher: any) => {
          dispatcher.checked = false;
        });
      }
    }
    dispatcher.checked = !dispatcher.checked
  }

  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.dialogRef.close();
  }

  okBtnClick() {    
    let toCheckedSupervisors:Array<Supervisor> = new Array<Supervisor>();
    let toCheckedDispatchers: Array<Dispatcher> = new Array<Dispatcher>();
    
    if (this.data.allSupervisors) toCheckedSupervisors = this.getCheckedSuper()
    
    if (this.data.allDispatchers) toCheckedDispatchers = this.getCheckedDispatcher();
    
    const shareData = {
      supervisors: ('allCheckedDispatchers' in this.data && 'allSupervisors' in this.data) 
        ? {toCheckedSupervisors:toCheckedSupervisors, allCheckedDispatchers: this.data.allCheckedDispatchers}
        : null,
      dispatchers: ('allCheckedDrivers' in this.data && 'allDispatchers' in this.data) 
        ? {toCheckedDispatchers:toCheckedDispatchers, toCheckedSupervisors:toCheckedSupervisors, allCheckedDrivers: this.data.allCheckedDrivers}
        : null,
    };
 
    this._result.next({ type: "ok", shareData, reassign: this.data.reassign });
  }

  getCheckedSuper(): Array<Supervisor> {
    return this.data.allSupervisors.filter((s: Supervisor) => s.checked);
  }

  getCheckedDispatcher(): Array<Dispatcher> {
    return this.data.allDispatchers.filter((s: Dispatcher) => s.checked);
  }

  isOkBtnDisabled(): boolean {
    switch (true) {
      // case this.data.hasOwnProperty('allCheckedDrivers') && this.data.hasOwnProperty('allCheckedDispatchers'):
      //   return (this.getCheckedSuper().length === 0) || (this.getCheckedDispatcher().length === 0);

      case !this.data.hasOwnProperty('allCheckedDrivers') && this.data.hasOwnProperty('allCheckedDispatchers'):
        return this.getCheckedSuper().length === 0;

      case this.data.hasOwnProperty('allCheckedDrivers') && !this.data.hasOwnProperty('allCheckedDispatchers'):
        return this.getCheckedDispatcher().length === 0 && this.getCheckedSuper().length === 0;

      default: return true;
    }
  }
    
}
