import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrailersService {

  constructor(
    private http: HttpClient
  ) { }

  loginByCompanyId(id: string): Observable<object> {
    const body = { companyId: id, project: 'RTM' };
    return this.http.post(`https://api.traxsphere.com/api/auth/loginUserByCompanyId`, body);
  }

  getTrailers(): Observable<any[]> {
    return this.http.get<any[]>(`https://api.traxsphere.com/api/RtmManage/trailers`)
  }
}
