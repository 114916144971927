import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reassigned-equipment',
  templateUrl: './reassigned-equipment.component.html',
  styleUrls: ['./reassigned-equipment.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignedEquipmentComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReassignedEquipmentComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  close(): void {
    this.dialogService.closeAllDialogs()
  }

  
}
