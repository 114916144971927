import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() idValue!: string;
  @Input() checked: boolean = false;
  @Output() emitter = new EventEmitter<boolean>();

  emitValue(checked: boolean): void {
    this.emitter.emit(checked);
  }
}
