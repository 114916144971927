import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicateToUsersComponent } from '../../../dispatch/components/duplicate-to-users/duplicate-to-users.component';

@Component({
  selector: 'app-duplicate-equipment',
  templateUrl: './duplicate-equipment.component.html',
  styleUrls: ['./duplicate-equipment.component.scss', '../../../../../main.module.styles.scss']
})
export class DuplicateEquipmentComponent implements OnInit {

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    console.log(this.data)
  }

  checkCarrier(carrier: any): void {
    carrier.checked = !carrier.checked
  }

  checkDriver(driver: any): void {
    driver.checked = !driver.checked
  }

}
