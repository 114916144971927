import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog/dialog.service';
import { CreateUserFormComponent } from '../create-user-form/create-user-form.component';
import { MatTab, MatTabChangeEvent, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { DialogComponent } from '../../../../../../components/dialog/dialog.component';

@Component({
  selector: 'app-create-user-popup',
  templateUrl: './create-user-popup.component.html',
  styleUrl: './create-user-popup.component.scss'
})
export class CreateUserPopupComponent implements AfterViewInit{

  @ViewChild(CreateUserFormComponent) createUserForm! : CreateUserFormComponent;
  @ViewChild('tabs') tabs!: MatTabGroup;
  onConfirm = new EventEmitter();

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateUserPopupComponent>
  ) {}
  
  public selectedTabIdx: number = 0;
  public formControl: any = {};

  ngAfterViewInit(): void { 
    this.tabs._handleClick = this.interceptTabChange.bind(this);
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if(this.selectedTabIdx === idx) return;
    
    if(this.createUserForm.isDirty){
      this.openWarningDialog('Please note that you can either add a dispatcher or a supervisor, but not both.').then(dialog=> {
        switch (dialog) {
          case 0: MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, 0]); break;
          case 1: MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, 1]); break;
          default: MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx]); break;
        }
      });
    } else MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx] )
  }

  handleFormValidity(childForm: any) {
    this.formControl = childForm;
  }

  resetForm() {
    this.createUserForm.userForm.reset();
  }
    
  closeBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, undefined);
  }

  okBtnClick() {

    switch (this.selectedTabIdx) {
      case 0: this.formControl.formData.roles='DISPATCHER'; break;
      case 1: this.formControl.formData.roles='SUPERVISOR'; break;
    }

    this.onConfirm.emit(this.formControl.formData);
  }

  openWarningDialog(message: string): Promise<number | null> {
    const warningIconStyle = { stroke: '#FEF0C7', width: '48px', height: '48px' };
    const btn1Style = {background: 'white', color: '#344054', border: '1px solid #D0D5DD'};
    const btn2Style = {background: '#175CD3', color: 'white', border: '1px solid #175CD3'};

    const dialogData = {
      title: 'Warning',
      text: message,
      button1Text: 'Add Dispatcher',
      button2Text: 'Add Supervisor',
      icon: 'warning-icon',
      iconStyle: warningIconStyle,
    };

    const dialogRef = this.dialogService.openDialog(DialogComponent, {
      props: { ...dialogData, btn1Style, btn2Style },
    });

    return new Promise((resolve) => {
      dialogRef.subscribe((dialog) => {
        if (!dialog) {
          resolve(null);
          return;
        }

        switch (dialog.result) {
          case 'button1':
            resolve(0);
          break;
          case 'button2':
            resolve(1);
          break;
        }
      });
    });
  }

}