<!-- <div class="main-layout">
    <app-sidebar></app-sidebar>
    <div class="outlet">
        <router-outlet />
    </div>
</div> -->

<div class="main-layout">
    <app-sidebar></app-sidebar>
    <div class="content-container">
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>