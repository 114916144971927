import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessRights } from '../../../../../../../interfaces/access-rights';
import { AUTH_API_URL } from '../../../../../../../services/app-injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class DuplicateService {

  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) {}

  duplicateConnections(scope: AccessRights, shares: any): Observable<any>  {
    let payload: any = {};

    if(!shares?.supervisors && shares.dispatchers) {
      const mapData = (shares: any) => {      
        const drivers = shares.dispatchers.allCheckedDrivers.map((driver: any) => ({
          id: driver.id,
          firstName: driver.fullName.split(' ')[0] || '',
          lastName: driver.fullName.split(' ')[1] || '',
          accessRights: scope
        }));

        let onlyDispatchers: Array<any> = new Array<any>();
        let supervisorDispatchers: Array<any> = new Array<any>();

        if(shares.dispatchers.toCheckedDispatchers.length > 0) {
          onlyDispatchers = shares.dispatchers.toCheckedDispatchers.map((dispatcher: any) => ({
            id: dispatcher.id,
            name: dispatcher.fullName,
            supervisorId: dispatcher.supervisorId,
          }));
        }

        if(shares.dispatchers.toCheckedSupervisors.length > 0) {
          supervisorDispatchers = shares.dispatchers.toCheckedSupervisors.map((supervisors: any) => ({
            id: supervisors.id,
            name: supervisors.fullName,
            supervisorId: 0,
          }));
        }

        let dispatchers = [...onlyDispatchers, ...supervisorDispatchers];
        
        payload = { drivers, dispatchers, supervisors: [] };
      };
       mapData(shares);
    }

    if(shares.supervisors && !shares?.dispatchers) {
      const mapData = (shares: any) => {      
        const dispatchers = shares.supervisors.allCheckedDispatchers.map((dispatcher: any) => ({
          id: dispatcher.id,
          name: dispatcher.fullName,
          supervisorId: dispatcher.supervisorId,
          accessRights: scope
        }));
      
        const supervisors = shares.supervisors.toCheckedSupervisors.map((supervisors: any) => ({
          id: supervisors.id,
          fullName: supervisors.fullName,
        }));
        payload = { dispatchers, supervisors, drivers: [] };
      };

       mapData(shares);
    }    
    return this.http.post(`${this.apiUrl}/api/manage/duplicate`, payload);
  }

}
