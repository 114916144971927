import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-snackbar',
  templateUrl: './success-snackbar.component.html',
  styleUrls: ['./success-snackbar.component.scss', '../snackbar.scss'],
  standalone: true
})
export class SuccessSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string | any) {}

  message = ''

  ngOnInit(): void {
    if (typeof this.data === 'string') {
      this.message = this.data
    } else if (typeof this.data === 'object') {
      console.log(this.data)
      // this.data.forEach((item: any) => {
      //   if (item?.driverName) {
      //     this.message = 'Driver ' + item.driverName + 'was shared with '

      //     if (item?.dispatcherNames.length > 0) {
      //       this.message = this.message + 'dispatchers '
      //       this.message = this.message + item?.dispatcherNames.join(', ')
      //     }
      //     if (item?.supervisorNames.length > 0) {
      //       if (item?.dispatcherNames.length > 0) {
      //         this.message = this.message + ' and supervisors '
      //       } else {
      //         this.message = this.message + 'supervisors '
      //         this.message = this.message + item?.supervisorNames.join(', ')
      //       }
      //     }
      //   }
      // })

    }
    console.log(this.message)
  }

}
