<div class="popup-wrapper">
    @if (data.carrier) {
        <div class="block">
            <h3>The carrier {{ data.carrier.Name }} got the equipment listed below:</h3>
            <div class="list">
                @if (data.trailers.length) {
                    <h4>Trailers</h4>
                    @for (trailer of data.trailers; track $index) {
                        <button class="row" [ngClass]="{ 'checked': trailer.checked }">{{ trailer.TrailerUnit }}</button>
                    }
                }
                @if (data.vehicles.length) {
                    <h4>Vehicles</h4>
                    @for (vehicle of data.vehicles; track $index) {
                        <button class="row" [ngClass]="{ 'checked': vehicle.checked }">{{ vehicle.VehicleUnit }}</button>
                    }
                }

            </div>
        </div>
    } @else if (data.driver) {
        <div class="block">
            <h3>The driver {{ data.driver.Name }} got the equipment listed below:</h3>
            <div class="list">
                @if (data.trailers.length) {
                    <h4>Trailers</h4>
                    @for (trailer of data.trailers; track $index) {
                        <button class="row" [ngClass]="{ 'checked': trailer.checked }">{{ trailer.trailerUnit }}</button>
                    }
                }
                @if (data.vehicles.length) {
                    <h4>Vehicles</h4>
                    @for (vehicle of data.vehicles; track $index) {
                        <button class="row" [ngClass]="{ 'checked': vehicle.checked }">{{ vehicle.vehicleUnit }}</button>
                    }
                }
            </div>
        </div>
    }

    <button (click)="close()">OK</button>
</div>