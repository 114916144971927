import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reassign-driver',
  templateUrl: './reassign-driver.component.html',
  styleUrls: ['./reassign-driver.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignDriverComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReassignDriverComponent>
  ) {}
  
  ngOnInit(): void {
    console.log(this.data)
  }

  checkCarrier(carrier: any): void {
    this.data.carriers.forEach((c: any) => c.checked = false)
    carrier.checked = true
  }

  onSave(): void {
    const checkedCarrier = this.data.carriers.find((s: any) => s.checked)

    this.dialogService.closeDialog(this.dialogRef, {
      carrier: checkedCarrier,
      driver: this.data.driver,
    });
  }

}
