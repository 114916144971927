import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicated-drivers',
  templateUrl: './duplicated-drivers.component.html',
  styleUrls: ['./duplicated-drivers.component.scss', '../../../../../main.module.styles.scss']
})
export class DuplicatedDriversComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  driverDups: {driverName: string, carrierNames: string[]}[] = []

  ngOnInit(): void {
    console.log(this.data)
    if (this.data?.drivers?.length) {
      this.driverDups = this.data.drivers.map((d: any) => { 
        return {
          driverName: d.Name,
          carrierNames: this.data.carriers.map((c: any) => c.Name),
        } 
      })
    }
  }

  close(): void {
    this.dialogService.closeAllDialogs()
  }

}
