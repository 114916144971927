<div class="popup-wrapper">

    <div class="header">
        <div class="img-block">
            <img src="assets/svg/credit-card-refresh.svg" alt="">
        </div>
        <button class="close" (click)="close()">
            <img src="assets/svg/x-close.svg" alt="">
        </button>
    </div>

    <h2 class="title">Reassign</h2>


    @if (data.supervisors && data.supervisors.length) {
        <div class="block">
            <h3 class="block-title">Select a supervisor</h3>
            <div class="list">
                @for (supervisor of data.supervisors; track $index) {
                    <button class="row" (click)="checkSupervisor(supervisor)">
                        <img class="row-img" src="assets/svg/Featured icon.svg" alt="">
                        <p class="name">{{ supervisor.firstName + ' ' + supervisor.lastName }}</p>
                        @if (supervisor.checked) { 
                            <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkSupervisor(supervisor)">
                        } @else {
                            <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkSupervisor(supervisor)">
                        }
                    </button>
                }
            </div>
        </div>
    }
    @if (data.dispatchers && data.dispatchers.length) {
        <div class="block">
            <h3 class="block-title">Or select a dispatcher</h3>
            <div class="list">
                @for (dispatcher of data.dispatchers; track $index) {
                    <button class="row" (click)="checkDispatcher(dispatcher)">
                        <img class="row-img" src="assets/svg/Featured icon2.svg" alt="">
                        <p class="name">{{ dispatcher.firstName + ' ' + dispatcher.lastName }}</p>
                        @if (dispatcher.checked) {
                            <img class="unchecked-img" src="assets/svg/checkbox-checked.svg" alt="checked" (click)="checkDispatcher(dispatcher)">
                        } @else {
                            <img class="unchecked-img" src="assets/svg/_Checkbox base.svg" alt="unchecked" (click)="checkDispatcher(dispatcher)">
                        }
                    </button>
                }
            </div>
        </div>
    }

    <div class="buttons">
        <button class="btn cancel" (click)="close()">Cancel</button>
        <button class="btn save" (click)="submit()">Reassign</button>
    </div>

</div>