import { Component, Inject, OnInit } from '@angular/core';
import { DriverDups } from '../../interfaces/driver-dups';
import { DispatcherDups } from '../../interfaces/dispatcher-dups';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicated-successfully',
  templateUrl: './duplicated-successfully.component.html',
  styleUrls: ['./duplicated-successfully.component.scss', '../../../../../main.module.styles.scss']
})
export class DuplicatedSuccessfullyComponent implements OnInit {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  driverDups: DriverDups[] = []
  dispatcherDups: DispatcherDups[] = []

  ngOnInit(): void {
    if (this.data?.drivers?.length) {
      this.driverDups = this.data.drivers.map((d: any) => { 
        return {
          driverName: d.Name,
          dispatcherNames: this.data.dispatchers.map((d: any) => d.Name),
          supervisorNames: this.data.supervisors.map((s: any) => s.Name)
        } 
      })
    } else if (this.data?.dispatchers?.length) {
      this.dispatcherDups = this.data.dispatchers.map((d: any) => {
        return {
          dispatcherName: d.Name,
          supervisorNames: this.data.supervisors.map((s: any) => s.Name)
        }
      })
    } else if (this.data.supervisors.length) {
      throw Error('We do not have functionality to duplicate supervisors yet!')
    }
  }

  close(): void {
    this.dialogService.closeAllDialogs()
  }

}
