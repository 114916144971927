import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {

  constructor(
    private http: HttpClient
  ) { }

  private apiUrl = environment.apiUrl

  getConnections(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/manage/duplicate/all`)
  }

  deleteConnections(ids: string[]): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}/api/manage/duplicate/remove`, {
      body: ids
    })
  }
}
