import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {

  @Input() imageSrc?: string
  @Input() value!: string
  @Input() type!: 'text' | 'password' | 'number'
  @Input() placeholder!: string
  @Input() func?: (value: string) => void

  invokeFunc(): void {
    if (this.func) {
      this.func(this.value)
    }
  }

}
