import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReassignService {

  constructor(
    private http: HttpClient,
  ) {}

  private apiUrl = environment.apiUrl

  reassignConnection(shares: any): Observable<any>  {
    let payload: any = {};

    if(!shares?.supervisors && shares.dispatchers) {
      const driversArr = shares.dispatchers.allCheckedDrivers.map((m:any)=> (m.id));
      const [firstElDispatcher] = shares.dispatchers.toCheckedDispatchers.length > 0 ? shares.dispatchers.toCheckedDispatchers : shares.dispatchers.toCheckedSupervisors;
      payload = {
        driverId: driversArr,
        dispatcherId: [firstElDispatcher.id],
        supervisorId: 0
      }
    }
   
    if(shares.supervisors && !shares?.dispatchers) {
      const dispatchersArr = shares.supervisors.allCheckedDispatchers.map((m:any)=> (m.id));
      const [firstElSuper] = shares.supervisors.toCheckedSupervisors;
      payload = {
        driverId: [0],
        dispatcherId: dispatchersArr,
        supervisorId: firstElSuper.id
      }
    }
    return this.http.post(`${this.apiUrl}/api/manage/assign`, payload);
  }
}
