<div class="share-block">
    <h3>{{ share.name }}</h3>
    @if (share.receivedDriverShares.length) {
        <div class="shares">
            <h4>Received shares</h4>
            @for (item of share.receivedDriverShares; track $index) {
                <div class="from-to" (click)="addShare(item.shareId)">
                    <p>{{ 'Author: ' + item.authorName }}</p>
                    <p>From: {{ share.name }}</p>
                    <p>To: {{ item.recipientName }}</p>
                    @if (item.selected) {
                        <img src="assets/svg/Avatar.svg" alt="checked">
                    }
                </div>
            }
        </div>
    }
    @if (share.sentDriverShares.length) {
        <div class="shares">
            <h4>Sent shares</h4>
            @for (item of share.sentDriverShares; track $index) {
                <div class="from-to" (click)="addShare(item.shareId)">
                    <p>{{ 'Author: ' + item.authorName }}</p>
                    <p>From: {{ share.name }}</p>
                    <p>To: {{ item.recipientName }}</p>
                    @if (item.selected) {
                        <img src="assets/svg/Avatar.svg" alt="checked">
                    }
                </div>
            }
        </div>
    }
</div>