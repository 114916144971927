import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reassigned-driver',
  templateUrl: './reassigned-driver.component.html',
  styleUrls: ['./reassigned-driver.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignedDriverComponent {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReassignedDriverComponent>
  ) {}

  close(): void {
    this.dialogService.closeAllDialogs()
  }
  
}
