<div class="popup-wrapper">

    <!-- Header Section -->
    <div class="header">
      <div class="img-block">
        <img src="assets/svg/access.svg" alt="Credit Card Icon" />
      </div>
      <h2 class="title">Select The Scope</h2>
      <button class="close" (click)="close()">
        <img src="assets/svg/x-close.svg" alt="close" />
      </button>
    </div>
    <div class="popup-content">
        <!-- Select Block: Editable -->
        <div class="select-block">
          <app-checkbox
            idValue="isEditable"
            [checked]="selectedScope.isEditable"
            (emitter)="onScopeChange('isEditable', $event)">
          </app-checkbox>
          <label for="isEditable">Can edit</label>
        </div>
      
        <!-- Select Block: Deletable -->
        <div class="select-block">
          <app-checkbox
            idValue="isDeletable"
            [checked]="selectedScope.isDeletable"
            (emitter)="onScopeChange('isDeletable', $event)">
          </app-checkbox>
          <label for="isDeletable">Can delete</label>
        </div>
      
        <!-- Select Block: Sharable -->
        <div class="select-block">
          <app-checkbox
            idValue="isSharable"
            [checked]="selectedScope.isSharable"
            (emitter)="onScopeChange('isSharable', $event)">
          </app-checkbox>
          <label for="isSharable">Can duplicate</label>
        </div>
      
        <!-- Select Block: Reassignable -->
        <div class="select-block">
          <app-checkbox
            idValue="isReassignable"
            [checked]="selectedScope.isReassignable"
            (emitter)="onScopeChange('isReassignable', $event)">
          </app-checkbox>
          <label for="isReassignable">Can reassign</label>
        </div>
    </div>
  
    <!-- Buttons Section -->
    <div class="buttons">
      <button class="btn cancel" (click)="closeBtnClick()">Cancel</button>
      <button class="btn save" (click)="okBtnClick()">Confirm</button>
    </div>
  
  </div>
  