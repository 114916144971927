import { Component, OnInit } from '@angular/core';
import { TrailersService } from './services/trailers/trailers.service';
import { forkJoin } from 'rxjs';
import { VehiclesService } from './services/vehicles/vehicles.service';
import { CarriersService } from './services/carriers/carriers.service';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { DuplicatedDriversComponent } from './components/duplicated-drivers/duplicated-drivers.component';
import { ReassignEquipmentComponent } from './components/reassign-equipment/reassign-equipment.component';
import { ReassignedEquipmentComponent } from './components/reassigned-equipment/reassigned-equipment.component';
import { ReassignDriverComponent } from './components/reassign-driver/reassign-driver.component';
import { ReassignedDriverComponent } from './components/reassigned-driver/reassigned-driver.component';
import { DuplicateDriversComponent } from './components/duplicate-drivers/duplicate-drivers.component';


interface DriverInfo {
  driverId: string
  Name: string
  checked: boolean
  selected: boolean
  DriverId?: string
  CarrierId: string
}

interface DriverBlock {
  carrierId: string
  carrierName: string
  array: DriverInfo[]
}

interface Equipment {
  driverId: string
  driverName: string
  carrierId: string
  carrierName: string
  trailerId: string
  trailerUnit: string
  vehicleUnit: string
  vehicleId: string
  trailerChecked: boolean
  vehicleChecked: boolean
  selected: boolean
  checked: boolean
}

interface EquipmentBlock {
  carrierId: string
  carrierName: string
  checked: boolean
  array: Equipment[]
}

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss', '../../../main.module.styles.scss']
})
export class CarrierComponent implements OnInit {

  constructor(
    private trailersService: TrailersService,
    private vehiclesService: VehiclesService,
    private carriersService: CarriersService,
    private dialog: DialogService
  ) {}

  allCarriers: any[] = []
  allDrivers: any[] = []
  allDriverBlocks: DriverBlock[] = []
  allEquipment: Equipment[] = []
  allEquipmentBlocks: EquipmentBlock[] = []
 
  carriers: any[] = []
  drivers: any[] = []
  trailers: any[] = []
  vehicles: any[] = []
 
  driverBlocks: DriverBlock[] = []
  equipmentBlocks: EquipmentBlock[] = []

  searchCarrierKey = ''
  searchDriverKey = ''
  searchEqKey = ''

  driversDisplay: 'list' | 'blocks' | 'both' = 'list'
  
  carriersChecked = false
  driversChecked = false
  equipmentChecked = false

  carriersCount = 0
  driversCount = 0
  equipmentCount = 0

  ngOnInit(): void {
    const trailers = this.trailersService.getTrailers()
    const vehicles = this.vehiclesService.getVehicles()
    const drivers = this.vehiclesService.getDrivers()
    const carriers = this.carriersService.getCarriers()
    
    this.trailersService.loginByCompanyId('7d588b99-c670-4e1d-a9df-8d2104a3548d').subscribe((t) => {

      forkJoin(trailers, vehicles, drivers, carriers)
      .subscribe(([trailers, vehicles, drivers, carriers]) => {
        this.carriers = carriers
        this.drivers = drivers
        this.trailers = trailers
        this.vehicles = vehicles;
        [...carriers, ...drivers, ...trailers, ...vehicles].forEach(item => { item.selected = false; item.checked = false })

        this.trailers.forEach(trailer => this.drivers.forEach(driver => {
          if (driver.TrailerId === trailer.TrailerId) {
            trailer.DriverId = driver.DriverId 
            trailer.DriverName = driver.Name
          }
        }))
        this.vehicles.forEach(vehicle => this.drivers.forEach(driver => {
          if (driver.VehicleId === vehicle.VehicleId) {
            vehicle.DriverId = driver.DriverId
            vehicle.DriverName = driver.Name
          }
        }))

        this.drivers.forEach(driver => {
          const trailerUnit = this.trailers.find(trailer => trailer.DriverId === driver.driverId)?.TrailerUnit
          const vehicleUnit = this.vehicles.find(vehicle => vehicle.VehicleId === driver.VehicleId)?.VehicleUnit
          
          this.allEquipment.push({
            driverId: driver.DriverId,
            driverName: driver.Name,
            carrierId: driver.CarrierId,
            carrierName: driver.CarrierName,
            trailerId: driver.TrailerId,
            trailerUnit: trailerUnit,
            vehicleUnit: vehicleUnit,
            vehicleId: driver.VehicleId,
            trailerChecked: false,
            vehicleChecked: false,
            selected: false,
            checked: false
          })
        })

        this.equipmentBlocks = this.getEquipmentBlocks(this.allEquipment)
        this.allCarriers = structuredClone(carriers)
        this.allDrivers = structuredClone(drivers)
        this.allEquipmentBlocks = structuredClone(this.equipmentBlocks)
        this.allDriverBlocks = this.getDriverBlocks(structuredClone(this.allCarriers), structuredClone(this.allDrivers))
      }) 
    })
  }

  selectCarrier(carrier: any): void {
    carrier.selected = !carrier.selected
    const selectedCarriers = this.carriers.filter(carrier => carrier.selected)
    if (selectedCarriers.length > 1) {
      this.driversDisplay = 'blocks'
      this.driverBlocks = this.getDriverBlocks(selectedCarriers, structuredClone(this.allDrivers))
      this.driverBlocks.forEach(block => block.array.forEach(d => d.selected = true))
      this.drivers = []
      this.equipmentBlocks = this.allEquipmentBlocks.filter(block => selectedCarriers.some(c => c.CarrierId === block.carrierId))
      this.equipmentBlocks.forEach(b => b.array.forEach(eq => eq.selected = true))
    } else if (selectedCarriers.length === 1) {
      this.driversDisplay = 'list'
      this.drivers = this.allDrivers.filter(d => d.CarrierId === selectedCarriers[0].CarrierId)
      this.drivers.forEach(d => d.selected = true)
      this.equipmentBlocks = this.allEquipmentBlocks.filter(block => selectedCarriers.some(c => c.CarrierId === block.carrierId))
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = true))
    } else {
      this.carriers = structuredClone(this.allCarriers)
      this.carriers.forEach(c => c.selected = false)
      this.driversDisplay = 'list'
      this.drivers = structuredClone(this.allDrivers)
      this.drivers.forEach(item => item.selected = false)
      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
    }
  }

  checkCarrier(carrier: any): void {
    carrier.checked = !carrier.checked
  }

  checkCarriers(): void {
    this.carriers.forEach(carrier => carrier.checked = !this.carriersChecked)
    this.carriersChecked = !this.carriersChecked
  }

  filterCarriersByKey(): void {
    this.carriers = this.allCarriers.filter(carrier => carrier.Name.toLowerCase().includes(this.searchCarrierKey.toLowerCase()))
  }

  clearCarriers(): void {
    this.carriers = structuredClone(this.allCarriers)
    this.carriers.forEach(carrier => { carrier.selected = false; carrier.checked = false })
    this.carriersChecked = false
    this.searchCarrierKey = ''
  }


  filterDriversByKey(): void {
    if (this.driversDisplay === 'list') {
      this.drivers = this.allDrivers.filter(driver => driver.Name.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
    } else if (this.driversDisplay === 'blocks') {
      this.driverBlocks = structuredClone(this.allDriverBlocks).map(block => {
        block.array = block.array.filter(d => (block.carrierName + d.Name).toLowerCase().includes(this.searchDriverKey.toLowerCase()))
        return block
      })
    } else if (this.driversDisplay === 'both') {
      this.driverBlocks = structuredClone(this.allDriverBlocks).map(block => {
        block.array = block.array.filter(d => (block.carrierName + d.Name).toLowerCase().includes(this.searchDriverKey.toLowerCase()))
        return block
      })
      this.drivers = this.getDriversList().filter(driver => driver.Name.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
    }
  }

  clearDrivers(): void {
    this.driverBlocks = []
    this.driversDisplay = 'list'
    this.drivers = structuredClone(this.allDrivers)
    this.drivers.forEach(driver => { driver.selected = false; driver.checked = false })
    this.driversChecked = false
    this.driverBlocks = []
    this.searchDriverKey = ''
  }

  checkDrivers(): void {
    this.drivers.forEach(driver => driver.checked = !this.driversChecked)
    this.driversChecked = !this.driversChecked
  }

  selectDriver(driver: any): void {
    driver.selected = !driver.selected

    let selectedDrivers: any[] = []

    if (this.driversDisplay === 'list') {
      selectedDrivers = this.drivers.filter(driver => driver.selected)
    } else if (this.driversDisplay === 'blocks') {
      const blockedDrivers = this.driverBlocks.flatMap(block => block.array)
      selectedDrivers = blockedDrivers.filter(driver => driver.selected)
    } else if (this.driversDisplay === 'both') {
      const selectedFromList = this.drivers.filter(dispatcher => dispatcher.selected)
      const drivers = this.driverBlocks.flatMap(block => block.array)
      const selectedFromBlocks = drivers.filter(driver => driver.selected)
      selectedDrivers = selectedFromList.concat(selectedFromBlocks)
    }

    this.carriers = this.allCarriers.filter(c => selectedDrivers.some(d => d.CarrierId === c.CarrierId))
    this.carriers.forEach(c => c.selected = true)

    if (this.carriers.length > 1) {
      this.driversDisplay = 'both'
      this.driverBlocks = this.getDriverBlocks(this.carriers, selectedDrivers)
      this.drivers = this.getDriversList()
      this.drivers.forEach(d => d.selected = false)

      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).filter(b => this.carriers.some(c => c.CarrierId === b.carrierId))
      this.equipmentBlocks = this.equipmentBlocks.map(b => {
        b.array = b.array.filter(eq => selectedDrivers.some(d => d.DriverId === eq.driverId))
        b.array.forEach(eq => eq.selected = true)
        return b
      })
    } else if (this.carriers.length === 1) {
      this.driversDisplay = 'list'
      this.drivers = structuredClone(this.allDrivers).map(d => {
        d.selected = selectedDrivers.some(selected => selected.DriverId === d.DriverId)
        return d
      })
      this.driverBlocks = []

      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).filter(b => this.carriers.some(c => c.CarrierId === b.carrierId))
      this.equipmentBlocks = this.equipmentBlocks.map(b => {
        b.array = b.array.filter(eq => selectedDrivers.some(d => d.DriverId === eq.driverId))
        b.array.forEach(eq => eq.selected = true)
        return b
      })
    } else {
      this.carriers = structuredClone(this.allCarriers)
      this.drivers = structuredClone(this.allDrivers)
      console.log(this.allEquipmentBlocks)
      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks);
      [...this.carriers, ...this.drivers].forEach(item => item.selected = false)
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
    }
  }

  checkDriver(driver: any): void {
    driver.checked = !driver.checked
  }

  selectEquipment(eq: Equipment): void {
    eq.selected = !eq.selected

    const allEquipment = this.equipmentBlocks.flatMap(block => block.array)
    const selectedEquipment = allEquipment.filter(eq => eq.selected)

    this.carriers = this.allCarriers.filter(c => selectedEquipment.some(eq => eq.carrierId === c.CarrierId))
    this.carriers.forEach(c => c.selected = true)

    if (selectedEquipment.length > 1) {
      this.driversDisplay = 'blocks'
      this.driverBlocks = this.getDriverBlocks(this.carriers, structuredClone(this.allDrivers))
      console.log(this.driverBlocks)
      this.driverBlocks = this.driverBlocks.map(block => {
        block.array = block.array.filter(d => selectedEquipment.some(eq => eq.driverId === d.DriverId))
        block.array.forEach(eq => eq.selected = true)
        return block
      })
    } else if (selectedEquipment.length === 1) {
      this.driversDisplay = 'list'
      this.driverBlocks = []
      this.drivers = this.allDrivers.filter(d => selectedEquipment.some(eq => eq.driverId === d.DriverId))
      this.drivers.forEach(d => d.selected = true)
    } else {
      this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
      this.equipmentBlocks.forEach(block => block.array.forEach(eq => eq.selected = false))
      this.driverBlocks = []
      this.driversDisplay = 'list'
      this.drivers = structuredClone(this.allDrivers)
      this.drivers.forEach(d => d.selected = false)
      this.carriers = structuredClone(this.allCarriers)
      this.carriers.forEach(c => c.selected = false)
    }
  }


  private getDriverBlocks(carriers: any[], drivers: any[]): DriverBlock[] {
    const blocks: DriverBlock[] = []

    carriers.forEach(carrier => {
      if (drivers.some(driver => driver.CarrierId === carrier.CarrierId)) {
        blocks.push({
          carrierId: carrier.CarrierId,
          carrierName: carrier.Name,
          array: []
        })

        drivers.forEach(driver => {
          if (driver.CarrierId === carrier.CarrierId) {
            const block = blocks.find(block => block.carrierId === driver.CarrierId)
            if (block) {
              block.array.push(driver)
            }
          }
        })

        blocks.forEach(block => {
          carriers.forEach(carrier => {
            if (block.carrierId === carrier.CarrierId) {
              block.carrierId = carrier.CarrierId
              block.carrierName = carrier.Name
            }
          })
        })
      }
    })

    return blocks
  }

  private getDriversList(): any[] {
    const blockedDrivers = this.driverBlocks.flatMap(block => block.array)
    const drivers = this.allDrivers.filter(driver => !blockedDrivers.some(item => driver.DriverId === item.DriverId))
    return drivers
  }

  private getEquipmentBlocks(equipment: Equipment[]): EquipmentBlock[] {
    const blocks: EquipmentBlock[] = []
    structuredClone(equipment).forEach(eq => {
      if (!blocks.some(block => block.carrierId === eq.carrierId)) {
        blocks.push({
          carrierId: eq.carrierId,
          carrierName: eq.carrierName,
          checked: false,
          array: []
        })
      }

      const block = blocks.find(block => block.carrierId === eq.carrierId)

      if (block) {
        block.array.push({
          driverId: eq.driverId,
          driverName: eq.driverName,
          carrierId: eq.carrierId,
          carrierName: eq.carrierName,
          trailerId: eq.trailerId,
          trailerUnit: eq.trailerUnit,
          vehicleUnit: eq.vehicleUnit,
          vehicleId: eq.vehicleId,
          trailerChecked: false,
          vehicleChecked: false,
          selected: false,
          checked: eq.checked
        })
      }
    })
    return blocks
  }

  // checkEquipments(): void {
  //   this.equipmentBlocks.forEach(block => block.array.forEach(eq => { eq.trailerChecked = !this.equipmentChecked; eq.vehicleChecked = !this.equipmentChecked }))
  //   this.equipmentChecked = !this.equipmentChecked
  // }

  checkEquipmentBlock(block: EquipmentBlock) : void {
    console.log(this.equipmentBlocks)
    console.log(block)
    block.checked = !block.checked
    block.array.forEach(eq => { eq.trailerChecked = block.checked; eq.vehicleChecked = block.checked })
  }

  clearEquipment(): void {
    this.equipmentBlocks = structuredClone(this.allEquipmentBlocks)
    this.equipmentBlocks.forEach(block => block.array.forEach(eq => { eq.selected = false; eq.trailerChecked = false; eq.vehicleChecked = false }))
    this.equipmentChecked = false
    this.searchEqKey = ''
  }

  checkEqTrailer(block: EquipmentBlock, eq: Equipment): void {
    eq.trailerChecked = !eq.trailerChecked
    block.checked = block.array.every(equip => equip.trailerChecked && equip.vehicleChecked)
  }

  checkEqVehicle(block: EquipmentBlock, eq: Equipment): void {
    eq.vehicleChecked = !eq.vehicleChecked
    block.checked = block.array.every(equip => equip.trailerChecked && equip.vehicleChecked)
  }

  searchEqByKey(): void {
    this.equipmentBlocks = structuredClone(this.allEquipmentBlocks).map(block => {
      block.array = block.array.filter(eq => {
        return (eq.carrierName + eq.driverName + eq.trailerUnit + eq.vehicleUnit).toLowerCase().includes(this.searchEqKey.toLowerCase())
      })
      return block
    })
  }

  onDuplicate(): void {
    const allCheckedDrivers = this.getAllCheckedDrivers()

    if (allCheckedDrivers.length) {
      this.dialog.openDialog(DuplicateDriversComponent, {
        carriers: structuredClone(this.allCarriers),
        checkedDrivers: structuredClone(allCheckedDrivers)
      }).subscribe(result => {
        if (result) {
          console.log(result)
          this.dialog.openDialog(DuplicatedDriversComponent, {
            carriers: result.carriers,
            drivers: structuredClone(allCheckedDrivers)
          });
        }
      });
    }
  }

  onReassign(): void {
    const allCheckedTrailers = this.getAllCheckedTrailers()
    const allCheckedVehicles = this.getAllCheckedVehicles()
    const allCheckedDrivers = this.getAllCheckedDrivers()

    if (allCheckedTrailers.length || allCheckedVehicles.length) {
      this.dialog.openDialog(ReassignEquipmentComponent, {
        carriers: structuredClone(this.allCarriers),
        drivers: structuredClone(this.allDrivers),
        trailers: structuredClone(allCheckedTrailers),
        vehicles: structuredClone(allCheckedVehicles)
      }).subscribe(result => {
        if (result) {
          this.dialog.openDialog(ReassignedEquipmentComponent, {
            carrier: result.carrier,
            driver: result.driver,
            trailers: result.trailers,
            vehicles: result.vehicles
          });
        }
      });
    } else if (allCheckedDrivers.length === 1) {
      this.dialog.openDialog(ReassignDriverComponent, {
        carriers: structuredClone(this.allCarriers),
        driver: structuredClone(allCheckedDrivers[0])
      }).subscribe(result => {
        if (result) {
          this.dialog.openDialog(ReassignedDriverComponent, {
            carrier: result.carrier,
            driver: result.driver
          });
        }
      })
    }
  }

  private getAllCheckedTrailers(): any[] {
    const trailers = this.equipmentBlocks.flatMap(block => block.array)
    const checked = trailers.filter(item => item.trailerChecked)
    return checked
  }

  private getAllCheckedVehicles(): any[] {
    const vehicles = this.equipmentBlocks.flatMap(block => block.array)
    const checked = vehicles.filter(item => item.vehicleChecked)
    return checked
  }

  private getAllCheckedDrivers(): any[] {
    let drivers: any[] = []

    if (this.driversDisplay === 'list') {
      drivers = this.drivers.filter(d => d.checked)
    } else if (this.driversDisplay === 'blocks') {
      const items = this.driverBlocks.flatMap(block => block.array)
      drivers = items.filter(d => d.checked)
    } else if (this.driversDisplay === 'both') {
      const fromList = this.drivers.filter(d => d.checked)
      const fromBlocks = this.driverBlocks.flatMap(block => block.array)
      drivers = fromList.concat(fromBlocks)
    }
    return drivers
  }

}
