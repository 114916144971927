<div class="popup-wrapper recycle-popup">

    <div class="header">
        <div class="img-block">
            <img src="assets/svg/delete.svg" alt="">
        </div>
        <h2 class="title">Deactivated</h2>
        <button class="close" (click)="close()">
            <img src="assets/svg/x-close.svg" alt="close">
        </button>
    </div>
    <div class="popup-content">
        <div class="users-content-container">
            <div class="users-content-card-header">
            <div class="user-header-card-left">
                <p class="user-header-card-title">Deactivated Users</p>
                <div class="badge">{{filteredUsers.length}}</div>
            </div>
            <div class="user-header-card-right">
                <div class="block-input-icon-with-err search-user-input">
                <svg-icon style="height: 20px;" src="assets/svg/search-icon.svg"></svg-icon>
                <input
                    type="text"
                    mode="Search"
                    placeholder="Search"
                    (input)="onFilterChange($event.target.value)" />
                </div>
            </div>
            </div>
            @defer (when filteredUsers.length > 0) {
                @if(filteredUsers.length > 0) {
                    <app-reusable-table 
                        [data]="filteredUsers"
                        [columns]="columns"
                        (select)="selectRows($event)"
                        [pageSize]="getNumbersOfRows()"
                    >
                    </app-reusable-table>
                } @else {
                    <div class="empty-dell">
                        <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                        <p>No deactivated users found</p>
                        <!-- <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span> -->
                    </div>
                }
            }
        </div>
    </div>
    
    <div class="buttons">
        <button class="btn cancel" (click)="restoreBtnClick()" [disabled]="restoreBtnDisabled()" [ngClass]="{'disabled-btn': restoreBtnDisabled()}">Restore</button>
        <button class="btn delete" (click)="deleteBtnClick()" [disabled]="true" [ngClass]="{'disabled-btn': true}">Permanently Delete</button>
    </div>
</div>