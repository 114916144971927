import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
// import { TimelineComponent } from './pages/timeline/timeline.component';
import { LoadsComponent } from './pages/loads/loads.component';
import { MapComponent } from './pages/map/map.component';
import { DispatchComponent } from './pages/manage-pages/dispatch/dispatch.component';
import { CarrierComponent } from './pages/manage-pages/carrier/carrier.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { UsersComponent } from './pages/users/users.component';
import { SupportComponent } from './pages/support/support.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuard } from '../../guards/auth/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    component: LayoutComponent, 
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
      { path: 'timeline', canActivate: [AuthGuard],  loadChildren: () => import('./pages/timeline/timeline.module').then(m => m.TimelineModule) },
      { path: 'loads',canActivate: [AuthGuard], component: LoadsComponent },
      { path: 'map',canActivate: [AuthGuard], component: MapComponent },
      { path: 'manage',canActivate: [AuthGuard], 
        children: [
          { path: 'carrier', component: CarrierComponent },
          { path: 'dispatch', component: DispatchComponent },
        ]
      },
      { path: 'reports', canActivate: [AuthGuard], component: ReportsComponent },
      { path: 'safety', canActivate: [AuthGuard], component: SafetyComponent },
      { path: 'users', canActivate: [AuthGuard], component: UsersComponent },
      { path: 'support', canActivate: [AuthGuard], component: SupportComponent },
      { path: 'settings', canActivate: [AuthGuard], component: SettingsComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
