import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicateToUsersComponent } from '../duplicate-to-users/duplicate-to-users.component';

@Component({
  selector: 'app-reassigned-successfully',
  templateUrl: './reassigned-successfully.component.html',
  styleUrls: ['./reassigned-successfully.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignedSuccessfullyComponent {

  constructor (
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  message = ''


  ngOnInit() {
    let text = ''

    if (this.data.driver) {
      text = `You\'ve reassigned the driver ${this.data.driver.Name} to the `
      if (this.data.dispatcher) {
        text = `You\'ve reassigned the driver ${this.data.driver.Name} to the dispatcher ${this.data.dispatcher.Name}`
      } else if (this.data.supervisor) {
        text = `You\'ve reassigned the driver ${this.data.driver.Name} to the dispatcher ${this.data.supervisor.Name}`
      }
    } else if (this.data.dispatcher) {
      text = `You\'ve reassigned the dispatcher ${this.data.dispatcher.Name} to the `
      if (this.data.supervisor) {
        text = `You\'ve reassigned the dispatcher ${this.data.dispatcher.Name} to the supervisor ${this.data.supervisor.Name}`
      }
    }
    this.message = text
  }

  close(): void {
    this.dialogService.closeAllDialogs()
  }

}
