<div class="popup-wrapper">
    <div class="block">
        <h3>Select carriers</h3>
        <div class="list">
            @for (carrier of data.carriers; track $index) {
                <button class="row" (click)="checkCarrier(carrier)" [ngClass]="{ 'checked': carrier.checked }">{{ carrier.Name }}</button>
            }
        </div>
    </div>

    <button (click)="onSave()">Duplicate</button>
</div>