import { Component, Input } from '@angular/core';
import { AdminShare } from '../../interfaces/admin-share';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss', '../../../../../main.module.styles.scss']
})
export class ConnectionComponent {

  @Input() share!: AdminShare
  @Input() addShareFunc?: (shareId: string) => void

  addShare(shareId: string): void {
    if (this.addShareFunc) {
      this.addShareFunc(shareId)
    }
  }

}
