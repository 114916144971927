<div class="manage-wrapper">
    <div class="manage-header">
        <div class="page-titles">
            <h2 class="title">Carrier Roles</h2>
            <p class="subtitle">Track, manage and forecast your customers and orders.</p>
        </div>
        <div class="actions">
            <button class="btn" (click)="{}">Delete connections</button>
            <button class="btn" (click)="onDuplicate()">Duplicate</button>
            <button class="btn" (click)="onReassign()">Reassign</button>
        </div>
    </div>
    <div class="manage-content">

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-blue"></div>
                    <h3 class="column-title">Carriers</h3>
                    <div class="count-block">{{ carriersCount }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" type="text" [(ngModel)]="searchCarrierKey" (input)="filterCarriersByKey()" />
                    </div>
                    <button class="clear" (click)="clearCarriers()">
                        <img class="clear-icon" src="assets/svg/clear.svg" alt="clear">
                    </button>
                </div>
            </div>

            <div class="hr-line blue-line"></div>

            <div class="list">
                <div class="list-header">
                    <input type="checkbox" id="checkbox-carriers" class="custom-checkbox minus" [checked]="carriersChecked" (click)="$event.stopPropagation(); checkCarriers()">
                    <label for="checkbox-carriers"></label>
                    <p class="list-header-text name">Name</p>
                    <p class="list-header-text">Actions</p>
                </div>

                <button class="row" *ngFor="let carrier of carriers; let i = index" [ngClass]="{ 'selected': carrier.selected }" (click)="selectCarrier(carrier)">
                    <input type="checkbox" [id]="'checkbox-carrier' + i" class="custom-checkbox" [checked]="carrier.checked" (click)="$event.stopPropagation(); checkCarrier(carrier)" />
                    <label [for]="'checkbox-carrier' + i" (click)="$event.stopPropagation()"></label>
                    <p class="name">{{ carrier.Name }}</p>
                    <button class="edit">
                        <img class="edit-icon" src="assets/svg/edit-pencil.svg" alt="edit">
                    </button>
                </button>

            </div>
        </div>

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-yellow"></div>
                    <h3 class="column-title">Drivers</h3>
                    <div class="count-block">{{ driversCount }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" type="text" [(ngModel)]="searchDriverKey" (input)="filterDriversByKey()" />
                    </div>
                    <button class="clear" (click)="clearDrivers()">
                        <img class="clear-icon" src="assets/svg/clear.svg" alt="clear">
                    </button>
                </div>
            </div>

            <div class="hr-line yellow-line"></div>

            <div class="list">
                <div class="list-header">
                    <input type="checkbox" id="checkbox-drivers" class="custom-checkbox minus" [checked]="driversChecked" (click)="$event.stopPropagation(); checkDrivers()">
                    <label for="checkbox-drivers"></label>
                    <p class="list-header-text name">Name</p>
                    <p class="list-header-text">Actions</p>
                </div>

                @if (driversDisplay === 'blocks' || driversDisplay === 'both') {
                    <div class="block" *ngFor="let block of driverBlocks; let blockIdx = index">
                        <div class="carrier-dispatcher">
                            <div class="users-check-block">
                                <img src="assets/svg/users-check.svg" alt="">
                            </div>
                            <div class="tied-users">
                                <div class="tied-user">
                                    <h4 class="supervisor">Carrier: </h4>
                                    <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                                </div>
                            </div>
                        </div>
                        
                        <button class="row" *ngFor="let driver of block.array; let driverIdx = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                            <input type="checkbox" [id]="'checkbox-dispatcher-block' + blockIdx + driverIdx" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                            <label [for]="'checkbox-dispatcher-block' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                            <p class="name">{{ driver.Name }}</p>
                            <button class="edit">
                                <img class="edit-icon" src="assets/svg/edit-pencil.svg" alt="edit">
                            </button>
                        </button>
                    </div>
                }
            
                @if(driversDisplay === 'list' || driversDisplay === 'both') {
                    <button class="row" *ngFor="let driver of drivers; let i = index" [ngClass]="{ 'selected': driver.selected }" (click)="selectDriver(driver)">
                        <input type="checkbox" [id]="'checkbox-driver' + i" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                        <label [for]="'checkbox-driver' + i" (click)="$event.stopPropagation()"></label>
                        <p class="name">{{ driver.Name }}</p>
                        <button class="edit">
                            <img class="edit-icon" src="assets/svg/edit-pencil.svg" alt="edit">
                        </button>
                    </button>
                }
            </div>
        </div>

        <div class="column">
            <div class="column-header">
                <div class="column-title-block">
                    <div class="point point-green"></div>
                    <h3 class="column-title">Equipment</h3>
                    <div class="count-block">{{ equipmentCount }}</div>
                </div>
                <div class="column-actions">
                    <div class="search-block">
                        <img class="search-img" src="assets/svg/search.svg" alt="search">
                        <input class="search-input" type="text" [(ngModel)]="searchEqKey" (input)="searchEqByKey()" />
                    </div>
                    <button class="clear" (click)="clearEquipment()">
                        <img class="clear-icon" src="assets/svg/clear.svg" alt="clear">
                    </button>
                </div>
            </div>

            <div class="hr-line green-line"></div>





            <div class="list eq-list">
                <div class="block" *ngFor="let block of equipmentBlocks; let blockIdx = index" style="background-color: #fff">
                    <div class="carrier-dispatcher">
                        <div class="users-check-block">
                            <img src="assets/svg/users-check.svg" alt="">
                        </div>
                        <div class="tied-users">
                            <div class="tied-user">
                                <h4 class="supervisor">Carrier: </h4>
                                <h4 class="supervisor-name">{{ block.carrierName }}</h4>
                            </div>
                        </div>
                    </div>

                    <div class="list-header">
                        <input type="checkbox" [id]="'checkbox-equipment-block' + blockIdx" class="custom-checkbox minus" [checked]="block.checked" (click)="$event.stopPropagation(); checkEquipmentBlock(block)">
                        <label [for]="'checkbox-equipment-block' + blockIdx"></label>
                        <p class="list-header-text name">Driver name</p>
                        <p class="list-header-text">Actions</p>
                    </div>
                    
                    <button class="eq-row" *ngFor="let eq of block.array; let driverIdx = index" [ngClass]="{ 'selected': eq.selected }" (click)="selectEquipment(eq)">
                        <div class="info">
                            <p>Driver : {{ eq.driverName }}</p>
                            <div class="equipment">
                                <div class="eq">
                                    <input type="checkbox" [id]="'checkbox-eq-trailer' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.trailerChecked" (click)="$event.stopPropagation(); checkEqTrailer(block, eq)" />
                                    <label [for]="'checkbox-eq-trailer' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                    <p>Trailer: {{ eq.trailerUnit }}</p>
                                </div>
                                <div class="eq">
                                    <input type="checkbox" [id]="'checkbox-eq-vehicle' + blockIdx + driverIdx" class="custom-checkbox" [checked]="eq.vehicleChecked" (click)="$event.stopPropagation(); checkEqVehicle(block, eq)" />
                                    <label [for]="'checkbox-eq-vehicle' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                                    <p>Vehicle: {{ eq.vehicleUnit }}</p>
                                </div>
                            </div>
                        </div>
                        <button class="edit">
                            <img class="edit-icon" src="assets/svg/edit-pencil.svg" alt="edit">
                        </button>


                        
                        
                        <!-- <input type="checkbox" [id]="'checkbox-dispatcher-block' + blockIdx + driverIdx" class="custom-checkbox" [checked]="driver.checked" (click)="$event.stopPropagation(); checkDriver(driver)" />
                        <label [for]="'checkbox-dispatcher-block' + blockIdx + driverIdx" (click)="$event.stopPropagation()"></label>
                        <p class="name">{{ driver.Name }}</p> 
                        <button class="edit">
                            <img class="edit-icon" src="assets/svg/edit-pencil.svg" alt="edit">
                        </button> -->
                    </button>
                </div>

                <!-- @for (block of equipmentBlocks; track $index) {
                    <div class="equipment-block">
                        <h4>{{ block.carrierName }}</h4>
                        @for (eq of block.array; track $index) {
                            <div class="equip" (click)="selectEquipment(eq)" [ngClass]="{ 'selected': eq.selected }">
                                <p>Driver: {{ eq.driverName }}</p>
                                <div class="eq-list">
                                    <div class="eq">
                                        @if (eq.trailerId && eq.trailerUnit) {
                                            <p>Trailer: {{ eq.trailerUnit }}</p>
                                            <input type="checkbox" (click)="$event.stopPropagation(); checkEqTrailer(eq)" [checked]="eq.trailerChecked">
                                        }
                                    </div>
                                    <div class="eq">
                                        @if (eq.vehicleId && eq.vehicleUnit) {
                                            <p>Vehicle: {{ eq.vehicleUnit }}</p>
                                            <input type="checkbox" (click)="$event.stopPropagation(); checkEqVehicle(eq)" [checked]="eq.vehicleChecked">
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                } -->
            </div>

        </div>

    </div>
</div>