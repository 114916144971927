import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicateToUsersComponent } from '../duplicate-to-users/duplicate-to-users.component';

@Component({
  selector: 'app-reassign-to-user',
  templateUrl: './reassign-to-user.component.html',
  styleUrls: ['./reassign-to-user.component.scss', '../../../../../main.module.styles.scss']
})
export class ReassignToUserComponent {

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DuplicateToUsersComponent>,
  ) {}

  checkSupervisor(supervisor: any): void {
    this.data.supervisors.forEach((s: any) => s.checked = false)
    this.data.dispatchers?.forEach((d: any) => d.checked = false)
    supervisor.checked = true
  }

  checkDispatcher(dispatcher: any): void {
    this.data.supervisors.forEach((s: any) => s.checked = false)
    this.data.dispatchers.forEach((d: any) => d.checked = false)
    dispatcher.checked = true
  }

  close(): void {
    this.dialogService.closeAllDialogs()
  }

  submit(): void {
    const checkedSupervisors = this.data.supervisors.filter((s: any) => s.checked)

    let checkedDispatchers = []
    if (this.data.dispatchers && this.data.dispatchers?.length) {
      checkedDispatchers = this.data.dispatchers?.filter((d: any) => d.checked)
    }

    this.dialogService.closeDialog(this.dialogRef, {
      supervisor: checkedSupervisors[0],
      dispatcher: this.data.dispatchers && this.data.dispatchers?.length && checkedDispatchers.length ? checkedDispatchers[0] : this.data.dispatcher,
      driver: this.data.driver
    });
  }

}
