<div class="popup-wrapper">
    @if (data?.drivers?.length) {
        <h2>You've successfully duplicated the drivers:</h2>
    } @else if (data?.dispatchers?.length) {
        <h2>You've successfully duplicated the dispatchers:</h2>
    }

    @if (data?.drivers?.length) {
        @for (dup of driverDups; track $index) {
            <div class="block" >
                <h3>Driver name: {{ dup.driverName }}</h3>
                @if (dup.dispatcherNames.length) {
                    <div class="list">
                        <h4>Dispatchers</h4>
                        @for (name of dup.dispatcherNames; track $index) {
                            <div class="row">{{ name }}</div>
                        }
    
                    </div>
                }

                @if (dup.supervisorNames.length) {
                    <div class="list">
                        <h4>Supervisors</h4>
                        @for (name of dup.supervisorNames; track $index) {
                            <div class="row">{{ name }}</div>
                        }
                    </div>
                }
            </div>
        }
    } @else if (data?.dispatchers?.length) {
        @for (dup of dispatcherDups; track $index) {
            <div class="block">
                <h3>Dispatcher name: {{ dup.dispatcherName }}</h3>
                <div class="list">
                    <h4>Supervisors</h4>
                    @for (name of dup.supervisorNames; track $index) {
                        <div class="row">{{ name }}</div>
                    }
                </div>
            </div>
        }
    }

    <button (click)="close()">OK</button>

</div>