<div class="popup-wrapper">
    @if (data.carriers && data.carriers.length) {
        <div class="block">
            <h3>Select a carrier</h3>
            <div class="list">
                @for (carrier of data.carriers; track $index) {
                    <button class="row" (click)="checkCarrier(carrier)" [ngClass]="{ 'checked': carrier.checked }">{{ carrier.Name }}</button>
                }
            </div>
        </div>
    }
    @if (data.drivers && data.drivers.length) {
        <div class="block">
            <h3>OR Select a driver</h3>
            <div class="list">
                @for (driver of data.drivers; track $index) {
                    <button class="row" (click)="checkDriver(driver)" [ngClass]="{ 'checked': driver.checked }">{{ driver.Name }}</button>
                }
            </div>
        </div>
    }
    <button class="duplicate" (click)="submit()">Reassign</button>
</div>