
<fieldset class="connection" (click)="check()">
    <legend class="author">Author of connection: {{ capitalizeFirstLetter(connection.authorName) }}</legend>
    <div class="from-to row">
        <svg-icon [src]="'assets/svg/'+getIcon(connection.fromRole)" class="avatar-icon"></svg-icon>
        <p> {{ capitalizeFirstLetter(connection.fromRole) }}: {{ capitalizeFirstLetter(connection.fromName) }} </p>
    </div>
    <svg-icon src="assets/svg/long-arrow-icon.svg" class="arrow"></svg-icon>
    <div class="shared row">
        <svg-icon [src]="'assets/svg/'+getIcon(connection.sharedUserRole)" class="avatar-icon"></svg-icon>
        <p>{{ capitalizeFirstLetter(connection.sharedUserRole) }}: {{ capitalizeFirstLetter(connection.sharedUserName) }}</p>
    </div>
    <svg-icon src="assets/svg/long-arrow-icon.svg" class="arrow"></svg-icon>
    <div class="from-to row">
        <svg-icon [src]="'assets/svg/'+getIcon(connection.toRole)" class="avatar-icon"></svg-icon>
        <p>{{ capitalizeFirstLetter(connection.toRole) }}: {{ capitalizeFirstLetter(connection.toName) }}</p>
    </div>
    <img [ngClass]="{'checked': connection.checked, 'unchecked': !connection.checked}" 
        [src]="connection.checked ? 'assets/svg/checkbox-checked.svg' : 'assets/svg/_Checkbox base.svg'" 
        alt="{{ connection.checked ? 'checked' : 'unchecked' }}"
        class="checkbox-img"
    >
</fieldset>
