<div class="popup-wrapper">
    <h2>You've successfully duplicated the drivers:</h2>

    @if (data?.drivers?.length) {
        @for (dup of driverDups; track $index) {
            <div class="block" >
                <h3>Driver name: {{ dup.driverName }}</h3>
                @if (dup.carrierNames.length) {
                    <div class="list">
                        <h4>Carriers</h4>
                        @for (name of dup.carrierNames; track $index) {
                            <div class="row">{{ name }}</div>
                        }
    
                    </div>
                }
            </div>
        }
    }

    <button (click)="close()">OK</button>
</div>