<div class="loads-wrapper">
    <div class="loads-header">
      <div class="page-titles">
        <h2 class="title">Loads</h2>
        <!-- <p class="subtitle">Track, manage and forecast your customers and orders.</p> -->
      </div>
      <div class="actions"></div>
    </div>
    <div class="loads-content-card custom-height">
      <div class="loads-content-card-header">
        <div class="loads-content-card-header-left">
          <div class="load-header-card">
            <p class="load-header-card-title">Loads</p>
            <div class="badge">{{countLoads()}} loads</div>
          </div>
           <!-- <div class="load-header-card-filter">

            <svg-icon class="cmnd-icons" src="assets/svg/clear-load.svg" (click)="clearFilters()"></svg-icon>
            <svg-icon class="cmnd-icons" src="assets/svg/refresh-load.svg" (click)="refreshTable()"></svg-icon>
            <button class="global-btn btn-create-load" (click)="createLoad(false)">
              <img class="btn-icon" src="assets/svg/plus.svg" alt="">
               <span>Create</span>
            </button>
          </div>  -->
        </div>

        <div class="loads-content-card-header-right">

          <mat-form-field class="search" appearance="outline" class="custom-multi-input-cont">
            <mat-label>Search</mat-label>
            <input id="co-driver-input" matInput [(ngModel)]="filterText" (ngModelChange)="onFilterChange()" type="text">
            <svg-icon src="assets/svg/search-icon.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont status-select">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="selectedStatus" panelClass="dropDwonMultipleFixClass" class="status-select" multiple>
              <mat-select-trigger>
                @switch (true) {
                @case (selectedStatus && (selectedStatus.length === 1)) {
                <div class="status-badge">
                  {{selectedStatus[0].name}}
                </div>
                }
                @case (selectedStatus && (selectedStatus.length === 2)) {
                <span class="example-additional-selection">
                  <div class="status-badge">
                    {{selectedStatus[0].name}}
                  </div>
                  <div class="status-badge">
                    {{selectedStatus[1].name}}
                  </div>
                </span>
                }
                @case (selectedStatus && (selectedStatus.length >= 3)) {
                <span class="example-additional-selection">
                  <div class="status-badge">
                    {{selectedStatus[0].name}}
                  </div>
                  <div class="status-badge">
                    {{selectedStatus[1].name}}
                  </div>
                  {{'+' + selectedStatus.length}}
                </span>
                }
                @default {}
                }
              </mat-select-trigger>
              @for (status of allStatusSource; track $index) {
              <mat-option id="statusOption" [value]="status" (click)="toggleStatus(status)">
                <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="status.selected"
                  (change)="toggleStatus(status)"><label [for]="'ms'+$index"></label>
                <label class="dl-label-style">
                  <div class="status-counter" [style.color]="checkLoadStatus(status.code)">
                    {{getLoadStatus(status)}}
                  </div>
                  {{ status.name }}
                </label>

              </mat-option>
              }
            </mat-select>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont">
            <mat-label>Driver</mat-label>
            <mat-select [(ngModel)]="selectedDriver" panelClass="dropDwonMultipleFixClass" multiple>
              @for (driver of allDriversSource; track $index) {
              <mat-option id="allOption" [value]="driver" (click)="toggleDriver(driver)">
                <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="driver.selected"
                  (change)="toggleDriver(driver)"><label [for]="'ms'+$index"></label>
                <label class="dl-label-style">{{ driver.driverFullName }}</label>
              </mat-option>
              }
            </mat-select>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont">
            <mat-label>Dispatcher</mat-label>
            <mat-select [(ngModel)]="selectedDispatcher" panelClass="dropDwonMultipleFixClass" multiple>
              @for (dispatcher of allDispatchersSource; track $index) {
              <mat-option id="allOption" [value]="dispatcher" (click)="toggleDispatcher(dispatcher)">
                <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="dispatcher.selected"
                  (change)="toggleDispatcher(dispatcher)"><label [for]="'ms'+$index"></label>
                <label class="dl-label-style">{{ dispatcher.fullName }}</label>
              </mat-option>
              }
            </mat-select>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>
          
          <mat-form-field appearance="outline" class="custom-multi-input-cont">
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="selectedCarrier" panelClass="dropDwonMultipleFixClass" multiple>
              @for (carrier of allCarriersSource; track $index) {
              <mat-option id="allOption" [value]="carrier" (click)="toggleCarrier(carrier)">
                <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="carrier.selected"
                  (change)="toggleCarrier(carrier)"><label [for]="'ms'+$index"></label>
                <label class="dl-label-style">{{ carrier.name }}</label>
              </mat-option>
              }
            </mat-select>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont">
            <mat-label>Broker</mat-label>
            <mat-select [(ngModel)]="selectedBroker" panelClass="dropDwonMultipleFixClass" multiple>
              @for (broker of allBrokersSource; track $index) {
              <mat-option id="allOption" [value]="broker" (click)="toggleBroker(broker)">
                <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="broker.selected"
                  (change)="toggleBroker(broker)"><label [for]="'ms'+$index"></label>
                <label class="dl-label-style">{{ broker.businessName }}</label>
              </mat-option>
              }
            </mat-select>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <div class="date-picker">
            <mat-datepicker-toggle matSuffix [for]="picker">
              <!-- <mat-icon matDatepickerToggleIcon>date_range</mat-icon> -->
              <mat-icon matDatepickerToggleIcon><svg-icon src="assets/svg/calendar-03-stroke-rounded 2.svg"></svg-icon></mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-input (click)="picker.open()" [rangePicker]="picker">
              <input [(ngModel)]="loadDateStart" readonly matStartDate (dateChange)="loadDateStart = $event.value" />
              <input [(ngModel)]="loadDateEnd" readonly matEndDate (dateChange)="dateChangeEnd($event)" />
            </mat-date-range-input>
            <mat-date-range-picker #picker style="position: absolute; top: 50px;"></mat-date-range-picker>
          </div>
          <div class="edit" [appTooltip]="editTooltipContent" [appTooltipPos]="editTooltipPos">
            <svg-icon class="dots-icon" src="assets/svg/three-dots.svg" alt="menu"></svg-icon>
            <ng-template #editTooltipContent>
                <ul class="loads-edit-tooltip">
                    <li (click)="createLoad(false)">
                        <svg-icon class="edit-icon" src="assets/svg/plus.svg" alt="create"></svg-icon>
                        <p>Create</p>
                    </li>
                    <li (click)="clearFilters()">
                        <svg-icon class="edit-icon" src="assets/svg/clean-noborder-icon.svg" alt="clean"></svg-icon>
                        <p>Clean</p>
                    </li>
                    <li appDebounceClick (debounceClick)="refreshTable()" [debounceTime]="300">
                        <svg-icon class="edit-icon" src="assets/svg/refresh-noborder-icon.svg" alt="refresh"></svg-icon>
                        <p>Refresh</p>
                    </li>
                </ul>
            </ng-template>
          </div>
        </div>
      </div>
      <app-reusable-table 
        [data]="filteredLoads" 
        [columns]="columns" 
        [drivers]="allDriversSource"
        [dispatcers]="allDispatchersSource"
        [carriers]="allCarriersSource"
        (edit)="editRow($event)" 
        (delete)="deleteRow($event, 'delete')" 
        (select)="selectRows($event)" 
        (refresh)="getAllLoads()"
        [pageSize]="getNumbersOfRows()">
      >
        <ng-template #expandedRowTemplate let-row>
          <div class="container-information">
            <div class="pickup">
              <div>
                <div style="display: flex;justify-content: space-between; align-items: center;">
                  <strong>{{ "Pickup Information" }}</strong>
                  <div style="display: flex">
                    <div style="margin: 5px" *ngFor="let item of row.pickup; let i = index">
                      <button class="inactive-btn" (click)="changeSelectedTab(i, 'pickup')" [ngClass]="{ 'active-btn': pIndex === i }" type="button">
                        {{ "Pickup" }}{{ i + 1 }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div *ngFor="let pickup of row.pickup; let i = index">
                  <div *ngIf="pIndex === i">
                    <div class="input-container">
                      <div class="block-inputs">
                        <label>Shipper</label>
                        <input [(ngModel)]="pickup.businessName" type="text" placeholder="Business Name" readonly/>
                      </div>
                      <div class="block-inputs">
                        <label>Phone Number</label>
                        <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                          ]" 
                          [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates"
                          [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                          [(ngModel)]="pickup.phone" disabled="true">
                        </ngx-intl-tel-input>
                      </div>
                    </div>
                    <div class="input-container">
                      <div style="width: 100%" class="block-input-icon">
                        <label>Location</label>
                        <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
                        <input [(ngModel)]="pickup.fullAddress" type="text" readonly/>
                      </div>
                    </div>
                    <div class="input-container">
                      <div class="block-input-icon">
                        <label>Scheduled</label>
                        <mat-datepicker-toggle matPrefix [for]="picker" class="date-picker-icon">
                          <mat-icon matDatepickerToggleIcon >date_range</mat-icon>
                        </mat-datepicker-toggle>
                        <input class="date-picker-input" style="margin: 0 0 3px 5px" [value]="getDate(pickup.dateScheduledFrom)" 
                          [matDatepicker]="picker" placeholder="Pickup Date" readonly/>
                        <mat-datepicker #picker [disabled]="true"></mat-datepicker>
                        <!-- <input  [(ngModel)]="pickup.pickupTime" type="time" placeholder="Pickup Time" /> -->
                      </div>
                      <div class="block-input-icon" style="width: 25%;">
                        <label>Check in</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/login-img.svg" alt="" /></mat-icon>
                        <input [value]="'n\a'" type="time" readonly/>
                      </div>
                      <div class="block-input-icon" style="width: 25%;">
                        <label>Check out</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/login-img.svg" alt="" /></mat-icon>
                        <input [value]="'n\a'" type="time" readonly/>
                      </div>
                    </div>
                    <div class="input-container">
                      <div class="block-input-icon">
                        <label>Full Name</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/person-img.svg" /></mat-icon>
                        <input  [(ngModel)]="pickup.fullName" type="text" placeholder="Full Name" readonly/>
                      </div>
                      <div class="block-input-icon">
                        <label>Email</label>
                        <mat-icon matPrefix><img src="/../assets/svg/email-img.svg" /></mat-icon>
                        <input style="margin: 3px 0 3px 5px"  [(ngModel)]="pickup.email" placeholder="Email" type="text" readonly/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="delivery">
              <div style="display: flex;justify-content: space-between; align-items: center;">
                <strong>{{ "Delivery Information " }}</strong>
                <div style="display: flex">
                  <div style="margin: 5px" *ngFor="let item of row.delivery; let i = index">
                    <button class="inactive-btn" type="button" (click)="changeSelectedTab(i, 'delivery')" [ngClass]="{ 'active-btn-delivery': dIndex === i }">
                      {{ "Delivery" }}{{ i + 1 }}
                    </button>
                  </div>
                </div>
              </div> 
              <div>
                <div *ngFor="let delivery of row.delivery; let i = index">
                  <div *ngIf="dIndex === i">
                    <div class="input-container">
                      <div class="block-inputs">
                        <label>Receiver</label>
                        <input [(ngModel)]="delivery.businessName" type="text" placeholder="Business Name" readonly/>
                      </div>
                      <div class="block-inputs">
                        <label>Phone Number</label>
                        <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                          ]" 
                          [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates"
                          [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                          [(ngModel)]="delivery.phone" disabled="true">
                        </ngx-intl-tel-input>
                      </div>
                    </div>
                    <div class="input-container">
                      <div style="width: 100%" class="block-input-icon">
                        <label>Location</label>
                        <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
                        <input [(ngModel)]="delivery.fullAddress" placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text" readonly/>
                      </div>
                    </div>
                    <div class="input-container">
                      <div class="block-input-icon">
                        <label>Scheduled</label>
                        <mat-datepicker-toggle class="date-picker-icon" matPrefix [for]="picker">
                          <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
                        </mat-datepicker-toggle>
                        <input class="date-picker-input" style="margin: 0 0 3px 5px"  [value]="getDate(delivery.dateScheduledFrom)"
                          [matDatepicker]="picker" placeholder="Delivery Date" />
                        <mat-datepicker #picker [disabled]="true"></mat-datepicker>
                        <!-- <input [value]="getDate(delivery.dateScheduledFrom)" type="time" placeholder="Delivery Time" readonly/> -->
                      </div>
                      <div class="block-input-icon" style="width: 25%;">
                        <label>Check in</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/login-img.svg" alt="" /></mat-icon>
                        <input [(ngModel)]="delivery.checkIn" type="time" placeholder="To" readonly/>
                      </div>
                      <div class="block-input-icon" style="width: 25%;">
                        <label>Check out</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/login-img.svg" alt="" /></mat-icon>
                        <input [(ngModel)]="delivery.checkOut" type="time" placeholder="To" readonly/>
                      </div>
                    </div>
                    <div class="input-container">
                      <div class="block-input-icon">
                        <label>Full Name</label>
                        <mat-icon matPreffix><img src="/../../../assets/svg/person-img.svg" /></mat-icon>
                        <input [(ngModel)]="delivery.fullName" type="text" placeholder="Full Name" readonly/>
                      </div>
                      <div class="block-input-icon">
                        <label>Email</label>
                        <mat-icon matPrefix><img src="/../assets/svg/email-img.svg" /></mat-icon>
                        <input style="margin: 3px 0 3px 5px" [(ngModel)]="delivery.email" placeholder="Email" type="text" readonly/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="customer-broker-info">
              <strong>Customer/Broker Information</strong>
              @if (row.broker) {
              <div style="gap: 16px; flex-direction: column;" class="input-container">
                <div class="block-inputs">
                  <label>Company</label>
                  <input type="text" [(ngModel)]="row.broker.businessName" placeholder="Select Company" readonly/>
                </div>
                <div class="block-inputs">
                  <label>Phone Number</label>
                  <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]" 
                    [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true"
                    [maxLength]="15" [phoneValidation]="true" name="phone" disabled="true" [(ngModel)]="row.broker.phone">
                  </ngx-intl-tel-input>
                </div>
                <div class="block-input-icon">
                  <label>Location</label>
                  <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
                  <input type="text" [(ngModel)]="row.broker.street" type="text" placeholder="Full Address" readonly/>
                </div>
                <div class="block-input-icon">
                  <label>Email</label>
                  <mat-icon><img src="/../../../assets/svg/email-img.svg" alt="" /></mat-icon>
                  <input type="text" [(ngModel)]="row.broker.email" readonly/>
                </div>
              </div>
              } @else {
              <div class="no-parial">
                <div>No broker data</div>
              </div>
              }
            </div>
          </div>

          
        </ng-template>
        @if(!isLoading) {
          <ng-template #emptyState>
            <div class="empty-state">
              <svg-icon src="assets/svg/find-icon.svg"></svg-icon>
              <div class="btns-wrapper">
                <button class="global-btn btn-clear" (click)="clearFilters()">
                  <span>Clear search</span>
               </button>
                <button class="global-btn btn-create-load" (click)="createLoad(true)">
                  <img class="btn-icon" src="assets/svg/plus.svg" alt="">
                   <span>Create</span>
                </button>
              </div>
            </div>
          </ng-template>
        }
      </app-reusable-table>
    </div>
  </div>
  