import { Component, EventEmitter, forwardRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, NG_VALUE_ACCESSOR, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-invite-user-popup',
  templateUrl: './invite-user-popup.component.html',
  styleUrls: ['../../../../../main/main.module.styles.scss', './invite-user-popup.component.scss']
})
export class InviteUserPopupComponent implements OnInit {

  onConfirm = new EventEmitter();
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InviteUserPopupComponent>,
  ) {
    this.form = this.fb.group({
      items: this.fb.array([this.createItem()])
    });
  }

  ngOnInit(): void {
    this.formValueChange();
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  createItem(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      supervisorId: [null, [Validators.required]],
      roles: ["Dispatcher", [Validators.required]]
    });
  }

  formValueChange() {
    this.items.controls.forEach((element:any) => {
      element.get('roles').valueChanges.subscribe((role:any) => {
        if(role === "Supervisor") {
          element.get('supervisorId').clearValidators();
        } else {
          element.get('supervisorId').setValidators([Validators.required]);
        }
        element.get('supervisorId').updateValueAndValidity();
      });
    });
  }

  addItem(): void {
    this.items.push(this.createItem());
    this.formValueChange();
  }

  removeItem(idx: number): void {
    this.items.controls.splice(idx, 1);
    this.items.updateValueAndValidity({ onlySelf: false });
    this.formValueChange();
  }

  
  closeBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, undefined);
  }

  okBtnClick() {
    this.onConfirm.emit(this.form.value.items);
  }
}